/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"
import {formatDate, formatTimeAsDuration} from "../common/datetime"
import {getDefaultHttpOverheadSizeInBytes, isHttpCodeOk} from "../common/httpCodes"
import {capitalizeFirstLetter, formatSizeInBytes, redactedString} from "../common/objects"
import {BoundingBox} from "../common/geo"

export class InspectorWindow extends HtmlWindow {
  constructor(htmlElementId: string) {
    super(htmlElementId)
    this.setVisible(false)
  }

  show(title: string, object: any, metadata: any = undefined) {
    const content = document.getElementById(`${this.htmlElement.id}-content`)
    if (!content) {
      throw new Error(`InspectorWindow: could not find element with id '${this.htmlElement.id}-content'`)
    }
    content.innerHTML = ""
    const element = document.createElement("p")
    const titleHtml = `<h1>${title.length > 0 ? title + "\n" : ""}</h1>`
    const metadataHtml = `${metadata ? this.formatMetadata(metadata) : ""}\n`
    const objectHtml = `${this.formatObject(object)}`
    element.innerHTML = `${titleHtml}${metadataHtml}${objectHtml}`
    content.appendChild(element)
    window.scrollTo({top: 0})
    this.setVisible(true)
  }

  private jsonAsRedactedString(object: any) {
    return object
      ? JSON.stringify(object, null, 2)
          .split("\n")
          .map((line) => redactedString(line))
          .join("\n")
      : "undefined"
  }

  private formatObject(object: any): string {
    return `<strong>Object:</strong>\n${this.jsonAsRedactedString(object)}\n`
  }

  private formatMetadata(metadata: any): string {
    const table = (value: string) => `<table style="border-collapse:collapse" >${value}</table>`
    const row1 = (col1: any, col2: any) =>
      `<tr><td class="table-header-1">${col1?.toString()}:</td><td class="table-value-1">${col2?.toString()}</td></tr>`
    const row2 = (col1: any, col2: any) =>
      `<tr><td class="table-header-2">${col1?.toString()}:</td><td class="table-value-2">${col2?.toString()}</td></tr>`
    const duration = (value: number) => `${formatTimeAsDuration(value)} (${value})`
    const remove = (metadata: any, field: string) => {
      delete metadata?.[field]
      return metadata
    }

    // Use a temporary record to remove fields to not duplicate that are represented in a table.
    let remaining = {...metadata}
    if (metadata.message) {
      // Use the 'message' property instead, if it exists.
      remaining = {...metadata.message}
    }

    let rows = ""
    if (remaining.duplicates) {
      rows += row1("Duplicates", remaining.duplicates)
      remaining = remove(remaining, "duplicates")
    }
    if (remaining.type) {
      rows += row1("Type", remaining.type)
      remaining = remove(remaining, "type")
    }
    if (remaining.layer) {
      rows += row1("Layer", remaining.layer)
      remaining = remove(remaining, "layer")
    }
    if (remaining.httpStatusCode) {
      rows += row1(
        "HTTP",
        `${remaining.httpStatusCode} ${isHttpCodeOk(remaining.httpStatusCode) ? " (OK)" : " (ERROR)"}`
      )
      remaining = remove(remaining, "httpStatusCode")
    }
    if (remaining.size !== undefined) {
      rows += row1(
        "Size",
        remaining.size === -1
          ? "(missing)"
          : `${formatSizeInBytes(remaining.size)}<small> (+${formatSizeInBytes(getDefaultHttpOverheadSizeInBytes())})</small>`
      )
      remaining = remove(remaining, "size")
    }

    rows += row1("Time", remaining.time ? formatDate(new Date(remaining.time)) : "missing")
    remaining = remove(remaining, "time")

    if (remaining.monotonicTime) {
      rows += row1("Monotonic", duration(remaining.monotonicTime))
      remaining = remove(remaining, "monotonicTime")
    }
    if (remaining.tileLevel) {
      rows += row1("Level", remaining.tileLevel)
      remaining = remove(remaining, "tileLevel")
    }
    if (remaining.tileX) {
      rows += row1("X", remaining.tileX)
      remaining = remove(remaining, "tileX")
    }
    if (remaining.tileY) {
      rows += row1("Y", remaining.tileY)
      remaining = remove(remaining, "tileY")
    }
    if (remaining.tileId) {
      rows += row1("ID", remaining.tileId)
      remaining = remove(remaining, "tileId")
    }
    if (remaining.bounds) {
      const box = remaining.bounds as BoundingBox
      rows += row1(
        "Bounds",
        `<small>(${box.southWest.lng.toFixed(4)}, ${box.southWest.lat.toFixed(4)}), (${box.northEast.lng.toFixed(4)}, ${box.northEast.lat.toFixed(4)})</small>`
      )
      remaining = remove(remaining, "bounds")
    }
    if (remaining.geoHash) {
      rows += row1("Geo hash", `<small>${remaining.geoHash}</small>`)
      remaining = remove(remaining, "geoHash")
    }
    if (remaining.lineNumber) {
      rows += row1("Line #", remaining.lineNumber)
      remaining = remove(remaining, "lineNumber")
    }
    if (remaining.line) {
      rows += row1("Line", `<small>${remaining.line}</small>`)
      remaining = remove(remaining, "line")
    }
    let html = table(rows)

    if (Object.keys(remaining).length !== 0) {
      rows = ""
      Object.keys(remaining).forEach((key) => {
        rows += row2(capitalizeFirstLetter(key), this.jsonAsRedactedString(remaining[key]))
      })
      html += table(rows)
    }
    return html
  }
}

export default InspectorWindow
