/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

export class LogWindow extends HtmlWindow {
  private logMessage: string
  private logMessagePrevious: string
  private logMessageRepeatCount: number

  constructor(htmlElementId: string) {
    super(htmlElementId)
    this.logMessage = ""
    this.logMessagePrevious = ""
    this.logMessageRepeatCount = 1
    this.setVisible(false)
  }

  log(text: string, clearFirst = false) {
    const maxLogLines = 29
    if (clearFirst) {
      this.logMessage = ""
    }
    if (text === this.logMessagePrevious) {
      ++this.logMessageRepeatCount
      this.logMessage = `${this.logMessage.split("\n").slice(0, -1).join("\n")}`
      text += ` [<-- repeated ${this.logMessageRepeatCount}x]`
    } else {
      this.logMessageRepeatCount = 1
      this.logMessagePrevious = text
    }
    this.logMessage = `${this.logMessage.split("\n").slice(-maxLogLines).join("\n")}${this.logMessage.length ? "\n" : ""}${text}`
    const logWindow = document.getElementById("log-window")!
    const logWindowContent = document.getElementById("log-window-content")!
    logWindowContent.innerHTML = ""
    const element = document.createElement("p")
    element.innerHTML = this.logMessage
    logWindowContent.appendChild(element)
    logWindow.scrollTo({top: logWindowContent.scrollHeight})
  }
}

export default LogWindow
