/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, GeoJsonProperties} from "geojson"

export enum LayerType {
  MapVisBasicMap = "MapVisBasicMap",
  MapVisIncidents = "MapVisIncidents",
  MapVisFlow = "MapVisFlow",
  MapVisHillshade = "MapVisHillshade",
  MapVisSatellite = "MapVisSatellite",
  MapVis3D = "MapVis3D",
  NdsLive = "NdsLive",
  NavTiles = "NavTiles",
  RoutingApi = "RoutingApi",
  CoordinatePairs = "CoordinatePairs",
  TTPLocation = "TTPLocation",
  TTP1s = "TTP1s",
  TTP100ms = "TTP100ms",
  GPX = "GPX",
  JSON = "JSON"
}

/**
 * This type describes the regex used to determine which parser is needed to
 * parse a single line of a layer type.
 */
export type LayerLineParserRegex = {
  layerType: LayerType
  regex: string
  color: GeoJsonProperties
}

/**
 * This type define a parser that parse a single line of a layer type.
 */
export type LayerLineParser = {
  name: string
  parseLine?: (lineNumber: number, line: string, color: GeoJsonProperties) => Feature[]
  color?: {
    "fill-opacity": number
    "fill-color": string
    "fill-outline-color": string
  }
}
