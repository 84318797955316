/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * Fixed overhead for HTTP calls, to be used as a default if real overhead is unknown.
 */
export const KEY_HTTP_OVERHEAD_SIZE_IN_BYTES = "http-overhead-size-in-bytes"

export function getDefaultHttpOverheadSizeInBytes() {
  let overhead = localStorage.getItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
  return overhead !== null ? parseInt(overhead) : 1024
}

export function setDefaultHttpOverheadSizeInBytes(sizeInBytes: number) {
  localStorage.setItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES, sizeInBytes.toString())
}

/**
 * Returns whether the HTTP result code indicated success.
 * @param code The HTTP result code.
 */
export function isHttpCodeOk(code: number): boolean {
  return code === 200 || code === 204 || code === 304
}

/**
 * Returns whether the HTTP result code indicated an authorization failure.
 * @param code The HTTP result code.
 */
export function isHttpCodeNotAuthorized(code: number): boolean {
  return code === 401 || code === 403
}
