/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MenuAction, MenuItem} from "./menuTypes"
import {Tools} from "./appTypes"

export class MenuBar {
  private readonly onSelectMap: (key: string) => void

  constructor(
    mapsMenu: MenuItem[],
    viewMenu: MenuItem[],
    layersMenu: MenuItem[],
    othersMenu: MenuItem[],
    onSelectMap: (key: string) => void
  ) {
    this.onSelectMap = onSelectMap

    let menuBarDiv = document.getElementById("menu-bar")!
    if (menuBarDiv.hasChildNodes()) {
      throw new Error("MenuBar: menubar is already initialized")
    }

    // Maps menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup(`Map`, mapsMenu, false, (name, button, action, id) => {
        button.textContent = name
        this.onSelectMap(id!)
      })
    )

    // View menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup("View", viewMenu, true, (name, button, action) => action && action())
    )

    // Layers menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup("Layers", layersMenu, true, (name, button, action) => action && action())
    )

    // Other actions.
    menuBarDiv.appendChild(
      this.createDropdownGroup("Others", othersMenu, true, (name, button, action) => action && action())
    )
  }

  static updateMenuItemToggle(menuItemId: string, value: boolean) {
    const menuItem = document.getElementById(menuItemId)
    if (menuItem) {
      const posSpace = menuItem.innerHTML.lastIndexOf(">") + 1 // Find the position of the last '>', 0 if not found.
      const text = menuItem.innerHTML.slice(posSpace)
      menuItem.innerHTML = `<code>${value ? '<span style="color: green;">&check;</span>' : '<span style="color: red;">&cross;</span>'}</span></code> ${text}`
    }
  }

  onWindowClick(event: MouseEvent) {
    const target = event.target as Element
    if (!target.matches(".dropdown-button")) {
      this.hideDropDowns()
    }
  }

  private createDropdownGroup(title: string, items: MenuItem[], showShortcut: boolean, menuAction?: MenuAction) {
    const div = document.createElement("div")
    div.classList.add("dropdown")

    // Menu bar item button.
    const button = document.createElement("button")
    button.classList.add("dropdown-button")
    button.textContent = title
    button.onclick = () => this.toggleButton(title)
    div.appendChild(button)

    // Menu bar list item button.
    const list = document.createElement("div")
    list.classList.add("dropdown-content")
    list.id = `${title}-dropdown`
    for (const item of items) {
      if ("separator" in item) {
        let hrElement = document.createElement("hr")
        list.appendChild(hrElement)
      } else {
        let aElement = document.createElement("a")
        aElement.href = "#"

        let menuItem = document.createElement("div")
        menuItem.classList.add("menu-item")

        let itemName = document.createElement("span")
        itemName.id = `${item.id}-menuitem`
        itemName.innerText = item.name
        menuItem.appendChild(itemName)

        if (showShortcut && item.shortcut) {
          let shortcut = document.createElement("span")
          shortcut.classList.add("menu-item-shortcut")
          shortcut.innerText = `${item.shortcut}`
          menuItem.appendChild(shortcut)
        }

        menuItem.onclick = () => (menuAction ? menuAction(item.name, button, item.action, item.id) : {})
        aElement.appendChild(menuItem)
        list.appendChild(aElement)
      }
    }

    div.appendChild(list)
    return div
  }

  private hideDropDowns() {
    const list = document.getElementsByClassName("dropdown-content")
    for (let element of Array.from(list)) {
      element.classList.remove("show")
    }
  }

  private toggleButton(id: string) {
    this.hideDropDowns()
    document.getElementById(`${id}-dropdown`)!.classList.toggle("show")
  }
}

export default MenuBar
