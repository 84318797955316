/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import App from "./app/app"
import {APPLICATION_VERSION} from "./common/version"

/**
 * Toggle visibility of app elements, used to clear the screen for the API key dialog.
 */
function setAppElementsVisibility(show: boolean) {
  ;["menu-bar", "map", "options-list", "layers-list", "data-files-list", "timeline-window"].forEach((id) => {
    const elm = document.getElementById(id)
    if (elm) {
      elm.style.display = show ? "block" : "none"
    } else {
      throw Error(`Cannot find HTML element with id: ${id}`)
    }
  })
}

let app

let apiKeyTomTom = localStorage.getItem(App.KEY_API_KEY_TOMTOM)
if (!apiKeyTomTom || apiKeyTomTom.length === 0) {
  // Pop-up the API key entry dialog.
  setAppElementsVisibility(false)
  document.getElementById("api-key-form")!.style.display = "block"
  document.getElementById("keys-form")!.addEventListener("submit", (e) => {
    e.preventDefault()
    apiKeyTomTom = (document.getElementById("api-key-tomtom") as HTMLInputElement).value.trim()
    localStorage.setItem(App.KEY_API_KEY_TOMTOM, apiKeyTomTom)
    document.getElementById("api-key-form")!.style.display = "none"
    setAppElementsVisibility(true)
    app = new App(APPLICATION_VERSION, apiKeyTomTom, document)
  })
} else {
  setAppElementsVisibility(true)
  app = new App(APPLICATION_VERSION, apiKeyTomTom, document)
}
