/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {formatDate} from "../common/datetime"
import {PointXY} from "../common/geo"
import {formatSizeInBytes, limitValue} from "../common/objects"
import Parser from "../parsers/parser"
import {getDefaultHttpOverheadSizeInBytes} from "../common/httpCodes"

export type Event = {
  timeMillis: number
  sizeInBytes?: number
  duplicates?: number
}

export type TimeRange = {
  minMillis: number
  maxMillis: number
}

type Bucket = {
  count: number
  sizeInBytes: number
}

type Rectangle = {
  x: number
  y: number
  width: number
  height: number
}

type SelectionRange = {
  minMillis: number
  maxMillis: number
  startHoldMillis: number
  endHoldMillis: number
}

/**
 * This class is a cache for the buckets calculation, to avoid recalculating them every time,
 * which is an expensive operation.
 */
class BucketCache {
  private static previousDisplayRange = {minMillis: 0, maxMillis: 0}
  private static previousEventsLength = 0
  private static buckets: Bucket[] = []

  static invalidateCache() {
    this.previousDisplayRange = {minMillis: 0, maxMillis: 0}
    this.previousEventsLength = 0
  }

  static getBuckets(events: Event[], displayRange: TimeRange, graphWidth: number): Bucket[] {
    // Cache the previous buckets (expensive to recalculate a lot)
    if (
      events.length === this.previousEventsLength &&
      this.previousDisplayRange.minMillis !== 0 &&
      this.previousDisplayRange.maxMillis !== 0 &&
      displayRange.minMillis === this.previousDisplayRange.minMillis &&
      displayRange.maxMillis === this.previousDisplayRange.maxMillis
    ) {
      return this.buckets
    }
    this.previousDisplayRange = {minMillis: displayRange.minMillis, maxMillis: displayRange.maxMillis}
    this.previousEventsLength = events.length

    const bucketSize = 20
    const totalNrOfBuckets = Math.floor(graphWidth / bucketSize)
    this.buckets = []
    for (let i = 0; i < totalNrOfBuckets; ++i) {
      this.buckets.push({count: 0, sizeInBytes: 0})
    }
    for (const event of events) {
      if (displayRange.minMillis <= event.timeMillis && event.timeMillis < displayRange.maxMillis) {
        let bucketIndex = limitValue(
          Math.floor(
            ((event.timeMillis - displayRange.minMillis) / (displayRange.maxMillis - displayRange.minMillis)) *
              totalNrOfBuckets
          ),
          0,
          totalNrOfBuckets - 1
        )
        this.buckets[bucketIndex].count++
        this.buckets[bucketIndex].sizeInBytes += event.sizeInBytes || 0
      }
    }
    return this.buckets
  }
}

/**
 * This is the timeline class, which is responsible for drawing the timeline.
 */
export class Timeline {
  private readonly container: HTMLElement
  private readonly onSetTimeRange: (timeRange: TimeRange) => void

  private readonly canvas = document.createElement("canvas")
  private readonly context = this.canvas.getContext("2d")!
  private readonly style = {
    font: "12px Roboto, Arial, Helvetica, sans-serif",
    fontAxis: "10px Roboto, Arial, Helvetica, sans-serif",
    fontSize: "11px Roboto, Arial, Helvetica, sans-serif",
    padding: 4,
    axisWidthY: 40,
    axisHeightX: 20,
    infoHeight: 20,
    backgroundColor: "rgb(31,49,71)",
    textColor: "rgb(255,255,255)",
    textColorAxis: "rgb(255,242,148)",
    textColorSize: "rgb(255,236,217)",
    textColorArrow: "rgb(255,243,224)",
    axisColor: "rgb(169,195,243)",
    selectionColor: "rgba(255,109,126,0.40)",
    barsColor: "rgb(87,112,168)",
    pointerColor: "rgb(255,255,255)"
  }
  private readonly textHeight = this.getTextHeight()
  private readonly minMoveUpdateIntervalMillis = 300
  private paddingAxisX = 5

  private displayRange: TimeRange
  private fullRange: TimeRange
  private selection: SelectionRange
  private cursorMillis: number
  private events: Event[]
  private isMouseDown = false

  private displayRangeNrOfItems: number
  private displayRangeNrOfItemsWithSize: number
  private displayRangeSizeInBytes: number
  private displayRangeDuplicatesSizeInBytes: number

  private selectionNrOfItems: number
  private selectionNrOfItemsWithSize: number
  private selectionSizeInBytes: number
  private selectionDuplicatesSizeInBytes: number

  private previousMoveUpdateMillis: number
  private previousTimeRange: TimeRange

  constructor(container: HTMLElement, onSetTimeRange: (timeRange: TimeRange) => void) {
    this.container = container
    this.container.appendChild(this.canvas)
    this.displayRange = {minMillis: 0, maxMillis: 0}
    this.fullRange = {minMillis: 0, maxMillis: 0}
    this.selection = {minMillis: 0, maxMillis: 0, startHoldMillis: 0, endHoldMillis: 0}
    this.displayRangeNrOfItems = 0
    this.displayRangeNrOfItemsWithSize = 0
    this.displayRangeSizeInBytes = 0
    this.displayRangeDuplicatesSizeInBytes = 0
    this.selectionNrOfItems = 0
    this.selectionNrOfItemsWithSize = 0
    this.selectionSizeInBytes = 0
    this.selectionDuplicatesSizeInBytes = 0
    this.previousMoveUpdateMillis = 0
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    this.events = []
    this.cursorMillis = 0

    this.onSetTimeRange = onSetTimeRange
    this.canvas.style.cursor = "pointer"

    window.addEventListener("resize", () => this.updateCanvasSize())
    this.updateCanvasSize()
    const canvas = this.canvas
    this.canvas.addEventListener(
      "mousedown",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseDown(point.x)
      },
      false
    )
    canvas.addEventListener(
      "mousemove",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseMove(point.x)
      },
      false
    )
    canvas.addEventListener(
      "mouseup",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseUp(point.x, point.y, event.shiftKey)
      },
      false
    )
    canvas.addEventListener(
      "mouseleave",
      () => {
        this.onMouseLeave()
      },
      false
    )
  }

  resetDisplayRange() {
    BucketCache.invalidateCache()
    this.displayRange = {minMillis: this.fullRange.minMillis, maxMillis: this.fullRange.maxMillis}
    this.selection = {minMillis: 0, maxMillis: 0, startHoldMillis: 0, endHoldMillis: 0}
    this.isMouseDown = false
    this.setTimeRangeIfChanged(this.getTimeRange(), true)
    this.draw()
  }

  setTimeRangeIfChanged(timeRange: TimeRange, forceUpdate: boolean = false) {
    const nowMillis = new Date().getTime()
    if (
      forceUpdate ||
      (timeRange.minMillis === 0 && timeRange.maxMillis === 0) ||
      (nowMillis - this.previousMoveUpdateMillis >= this.minMoveUpdateIntervalMillis &&
        (this.previousTimeRange.minMillis !== timeRange.minMillis ||
          this.previousTimeRange.maxMillis !== timeRange.maxMillis))
    ) {
      this.previousMoveUpdateMillis = nowMillis
      this.previousTimeRange = {minMillis: timeRange.minMillis, maxMillis: timeRange.maxMillis}
      const sizes = this.calculateRangesSizeInBytes(
        this.getSelectionRange(),
        this.displayRange,
        getDefaultHttpOverheadSizeInBytes()
      )
      this.selectionNrOfItems = sizes.nrOfItems1
      this.selectionNrOfItemsWithSize = sizes.nrOfItemsWithSize1
      this.selectionSizeInBytes = sizes.sizeInBytes1
      this.selectionDuplicatesSizeInBytes = sizes.duplicatesSizeInBytes1
      this.displayRangeNrOfItems = sizes.nrOfItems2
      this.displayRangeNrOfItemsWithSize = sizes.nrOfItemsWithSize2
      this.displayRangeSizeInBytes = sizes.sizeInBytes2
      this.displayRangeDuplicatesSizeInBytes = sizes.duplicatesSizeInBytes2
      this.onSetTimeRange(timeRange)
    }
  }

  replaceAllEvents(events: Event[]) {
    BucketCache.invalidateCache()
    this.events = []
    this.cursorMillis = 0
    this.fullRange = {minMillis: 0, maxMillis: 0}
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    events.forEach((event) => this.addEventsWithoutRedraw(event))
    this.displayRange = {minMillis: this.fullRange.minMillis, maxMillis: this.fullRange.maxMillis}
    this.setTimeRangeIfChanged(this.getTimeRange(), true)
    this.draw()
  }

  getTimeRange(): TimeRange {
    const selectionRange = this.getSelectionRange()
    const minMills =
      this.selection.minMillis === this.selection.maxMillis ? this.displayRange.minMillis : selectionRange.minMillis
    const maxMillis =
      this.selection.minMillis === this.selection.maxMillis ? this.displayRange.maxMillis : selectionRange.maxMillis
    return {minMillis: minMills, maxMillis: maxMillis}
  }

  public recalculateSizesAndDraw() {
    this.setTimeRangeIfChanged(this.getTimeRange(), true)
    this.draw()
  }

  public draw() {
    const graphRect = this.getGraphRect()
    const axisRectY = this.getAxisRectY()
    const axisRectX = this.getAxisRectX()
    const infoRect = this.getInfoRect()
    let buckets = BucketCache.getBuckets(this.events, this.displayRange, graphRect.width)

    this.context.fillStyle = this.style.backgroundColor
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.drawAxisX(axisRectX)
    this.drawAxisY(axisRectY, buckets)
    this.drawGraph(graphRect, buckets)
    this.drawSelection()
    this.drawCursor()
    this.drawInfo(infoRect, buckets)
  }

  private getSelectionRange(): TimeRange {
    const shift = this.selection.endHoldMillis - this.selection.startHoldMillis
    return {
      minMillis: Math.min(this.selection.minMillis, this.selection.maxMillis) + shift,
      maxMillis: Math.max(this.selection.minMillis, this.selection.maxMillis) + shift
    }
  }

  private onMouseDown(pointX: number) {
    this.isMouseDown = true
    const rect = this.getGraphRect()
    pointX -= rect.x

    const selectionTimeRange = this.getSelectionRange()
    const edgeInPixels = 15

    const pointInMillis =
      this.displayRange.minMillis + (pointX / rect.width) * (this.displayRange.maxMillis - this.displayRange.minMillis)

    // Convert millis to pixels.
    const minEdgeX =
      ((selectionTimeRange.minMillis - this.displayRange.minMillis) /
        (this.displayRange.maxMillis - this.displayRange.minMillis)) *
      rect.width
    const maxEdgeX =
      ((selectionTimeRange.maxMillis - this.displayRange.minMillis) /
        (this.displayRange.maxMillis - this.displayRange.minMillis)) *
      rect.width
    const isNearMinEdge = Math.abs(pointX - minEdgeX) < edgeInPixels
    const isNearMaxEdge = Math.abs(pointX - maxEdgeX) < edgeInPixels

    if (isNearMinEdge || isNearMaxEdge) {
      this.selection.startHoldMillis = 0
      this.selection.endHoldMillis = 0
      // Extend selection if near existing time range edges.
      if (isNearMinEdge) {
        this.selection.minMillis = pointInMillis
      } else {
        this.selection.maxMillis = pointInMillis
      }
    } else {
      // Move time range if clicked inside the selection.
      if (selectionTimeRange.minMillis <= pointInMillis && pointInMillis <= selectionTimeRange.maxMillis) {
        this.selection.startHoldMillis = pointInMillis
        this.selection.endHoldMillis = pointInMillis
      } else {
        // Create new time range if clicked outside.
        this.selection.startHoldMillis = 0
        this.selection.endHoldMillis = 0
        this.selection.minMillis = pointInMillis
        this.selection.maxMillis = pointInMillis
      }
    }
    this.draw()
  }

  private onMouseMove(pointX: number) {
    const rect = this.getGraphRect()
    pointX -= rect.x

    const selectionTimeRange = this.getSelectionRange()
    const pointInMillis =
      this.displayRange.minMillis + (pointX / rect.width) * (this.displayRange.maxMillis - this.displayRange.minMillis)
    const extendLeft = pointInMillis < (selectionTimeRange.maxMillis + selectionTimeRange.minMillis) / 2

    if (this.isMouseDown) {
      if (this.selection.startHoldMillis !== 0) {
        this.selection.endHoldMillis = pointInMillis
      } else {
        if (extendLeft) {
          this.selection.minMillis = pointInMillis
        } else {
          this.selection.maxMillis = pointInMillis
        }
      }
      // Update the range only every second to avoid a lot of redraws.
      if (this.selection.minMillis !== this.selection.maxMillis) {
        this.setTimeRangeIfChanged(this.getTimeRange())
      }
      this.cursorMillis = 0
    } else {
      this.cursorMillis = pointInMillis
    }
    this.draw()
  }

  private onMouseUp(pointX: number, pointY: number, shiftKey: boolean) {
    if (!this.isMouseDown) {
      return
    }
    this.isMouseDown = false
    let shiftMillis = this.selection.endHoldMillis - this.selection.startHoldMillis
    this.selection.minMillis = Math.max(this.displayRange.minMillis, this.selection.minMillis + shiftMillis)
    this.selection.maxMillis = Math.min(this.displayRange.maxMillis, this.selection.maxMillis + shiftMillis)
    this.selection.startHoldMillis = 0
    this.selection.endHoldMillis = 0

    if (shiftKey) {
      const selectionRange = this.getSelectionRange()
      if (selectionRange.minMillis === selectionRange.maxMillis) {
        // Reset display range.
        this.displayRange = {minMillis: this.fullRange.minMillis, maxMillis: this.fullRange.maxMillis}
      } else {
        // Set new display range.
        this.displayRange = {minMillis: selectionRange.minMillis, maxMillis: selectionRange.maxMillis}
      }

      // Reset selection.
      this.selection = {minMillis: 0, maxMillis: 0, startHoldMillis: 0, endHoldMillis: 0}
    }
    this.setTimeRangeIfChanged(this.getTimeRange(), true)
    this.draw()
  }

  private onMouseLeave() {
    this.cursorMillis = 0
    this.setTimeRangeIfChanged(this.getTimeRange())
    this.draw()
  }

  private calculateRangesSizeInBytes(
    range1: TimeRange,
    range2: TimeRange,
    httpOverheadSizeInBytes: number
  ): {
    nrOfItems1: number
    nrOfItemsWithSize1: number
    sizeInBytes1: number
    duplicatesSizeInBytes1: number
    nrOfItems2: number
    nrOfItemsWithSize2: number
    sizeInBytes2: number
    duplicatesSizeInBytes2: number
  } {
    let nrOfItems1 = 0
    let nrOfItemsWithSize1 = 0
    let sizeInBytes1 = 0
    let duplicatesSizeInBytes1 = 0

    let nrOfItems2 = 0
    let nrOfItemsWithSize2 = 0
    let sizeInBytes2 = 0
    let duplicatesSizeInBytes2 = 0

    /**
     * Calculate the total number of items and the total size in bytes for the given ranges.
     * If 'size' is not defined for an event, the event does not have a size in its definition,
     * so it does not mark the range as incomplete. If it is '-1', it was missing where it
     * was supposed to be found, so it marks the range as incomplete.
     */
    for (const event of this.events) {
      if (range1.minMillis <= event.timeMillis && event.timeMillis <= range1.maxMillis) {
        ++nrOfItems1
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++nrOfItemsWithSize1
          if (event.sizeInBytes !== undefined) {
            sizeInBytes1 += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              duplicatesSizeInBytes1 += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
      if (range2.minMillis <= event.timeMillis && event.timeMillis <= range2.maxMillis) {
        ++nrOfItems2
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++nrOfItemsWithSize2
          if (event.sizeInBytes !== undefined) {
            sizeInBytes2 += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              duplicatesSizeInBytes2 += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
    }
    return {
      nrOfItems1: nrOfItems1,
      nrOfItemsWithSize1: nrOfItemsWithSize1,
      sizeInBytes1: sizeInBytes1,
      duplicatesSizeInBytes1: duplicatesSizeInBytes1,
      nrOfItems2: nrOfItems2,
      nrOfItemsWithSize2: nrOfItemsWithSize2,
      sizeInBytes2: sizeInBytes2,
      duplicatesSizeInBytes2: duplicatesSizeInBytes2
    }
  }

  private addEventsWithoutRedraw(event: Event) {
    this.fullRange.minMillis =
      this.fullRange.minMillis === 0 ? event.timeMillis : Math.min(this.fullRange.minMillis, event.timeMillis)

    this.fullRange.maxMillis =
      this.fullRange.maxMillis === 0 ? event.timeMillis : Math.max(this.fullRange.maxMillis, event.timeMillis)
    this.events.push(event)
  }

  private convertMouseXYToCanvasXY(event: MouseEvent): PointXY {
    let rect = this.canvas.getBoundingClientRect()
    let x = event.clientX - rect.left
    let y = event.clientY - rect.top
    return {x: x, y: y}
  }

  private updateCanvasSize() {
    this.canvas.width = this.container.clientWidth
    this.canvas.height = this.container.clientHeight
    this.draw()
  }

  private getTextHeight() {
    let elememt = document.createElement("div")
    elememt.style.font = this.style.font
    elememt.textContent = "a"
    document.body.appendChild(elememt)
    let height = elememt.offsetHeight
    document.body.removeChild(elememt)
    return height
  }

  private getTextWidth(text: string) {
    this.context.font = this.style.font
    let metrics = this.context.measureText(text)
    return metrics.width
  }

  private getGraphRect(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.style.padding,
      width: this.canvas.width - this.style.padding * 2 - this.style.axisWidthY,
      height: this.canvas.height - this.style.padding * 2 - this.style.axisHeightX
    }
  }

  private getInfoRect(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.style.padding,
      width: this.canvas.width - this.style.padding * 2 - this.style.axisWidthY,
      height: this.style.infoHeight
    }
  }

  private getAxisRectY(): Rectangle {
    return {
      x: this.style.padding,
      y: this.style.padding,
      width: this.style.axisWidthY,
      height: this.canvas.height - this.style.padding * 2 - this.style.axisHeightX
    }
  }

  private getAxisRectX(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.canvas.height - this.style.padding - this.style.axisHeightX,
      width: this.canvas.width - this.style.padding * 2 - this.style.axisWidthY,
      height: this.style.axisHeightX
    }
  }

  private isRangeInSingleDay(): boolean {
    const date1 = new Date(this.displayRange.minMillis)
    const date2 = new Date(this.displayRange.maxMillis)
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private formatTimeMillis(timeMillis: number) {
    if (this.isRangeInSingleDay()) {
      return new Date(timeMillis).toLocaleTimeString(undefined, {hour12: false})
    } else {
      return formatDate(new Date(timeMillis))
    }
  }

  private drawAxisX(rect: Rectangle) {
    this.drawLine({x: rect.x, y: rect.y}, {x: rect.x + rect.width, y: rect.y}, this.style.axisColor, 1)
    const range = this.displayRange.maxMillis - this.displayRange.minMillis
    if (range <= 0) {
      return
    }
    const textWidth = this.getTextWidth(this.formatTimeMillis(this.displayRange.maxMillis))
    const count = Math.floor(rect.width / (2 * textWidth))

    for (let i = 0; i <= count; ++i) {
      const midX = rect.x + (i * rect.width) / count
      this.drawLine({x: midX, y: rect.y}, {x: midX, y: rect.y + 7}, this.style.axisColor, 1)
      this.drawTextAxis(
        this.formatTimeMillis(this.displayRange.minMillis + (range * i) / count),
        {x: midX, y: rect.y + 10},
        "center",
        "top"
      )
    }
  }

  private drawAxisY(rect: Rectangle, buckets: Bucket[]) {
    this.drawLine(
      {x: rect.x + rect.width, y: rect.y},
      {x: rect.x + rect.width, y: rect.y + rect.height},
      this.style.axisColor,
      1
    )
    let count = Math.floor(rect.height / this.textHeight)
    let verticalSpacing = rect.height / count

    let max = 0
    for (const element of buckets) {
      max = Math.max(max, element.count)
    }

    for (let i = 0; i <= count; ++i) {
      const midY = rect.y + i * verticalSpacing
      this.drawLine({x: rect.x + rect.width - 5, y: midY}, {x: rect.x + rect.width, y: midY}, this.style.axisColor, 1)
      const value = max * (1 - i / count)
      this.drawTextAxis(
        `${i === count || value ? value.toFixed(0) : ""}`,
        {x: rect.x + rect.width - 8, y: midY},
        "right",
        "middle"
      )
    }
  }

  private drawInfo(rect: Rectangle, buckets: Bucket[]) {
    if (this.displayRange.minMillis === this.displayRange.maxMillis) {
      return
    }

    let line1 = ""
    if (this.cursorMillis !== 0) {
      let position = limitValue(
        Math.floor(
          ((this.cursorMillis - this.displayRange.minMillis) /
            (this.displayRange.maxMillis - this.displayRange.minMillis)) *
            buckets.length
        ),
        0,
        buckets.length - 1
      )
      line1 += `Cursor: ${this.formatTimeMillis(this.cursorMillis)}, ${formatSizeInBytes(buckets[position].sizeInBytes)}, ${buckets[position].count} events  |  `
    }

    if (this.selection.minMillis !== this.selection.maxMillis) {
      const selectionRange = this.getSelectionRange()
      line1 += `Selection: ${this.formatTimeMillis(selectionRange.minMillis)} to ${this.formatTimeMillis(selectionRange.maxMillis)}${this.selectionSizeInBytes ? ", " + formatSizeInBytes(this.selectionSizeInBytes) : ""}${this.selectionDuplicatesSizeInBytes ? " (" + formatSizeInBytes(this.selectionDuplicatesSizeInBytes) + " in duplicates)" : ""}, ${this.selectionNrOfItems} items${this.selectionNrOfItems === this.selectionNrOfItemsWithSize ? "" : " (" + (this.selectionNrOfItems - this.selectionNrOfItemsWithSize) + " incomplete)"}  |  `
    }

    if (this.isRangeInSingleDay()) {
      line1 += `Day: ${formatDate(new Date(this.displayRange.minMillis), false)}  |  `
    }

    line1 += `Timeline: ${this.formatTimeMillis(this.displayRange.minMillis)} to ${this.formatTimeMillis(this.displayRange.maxMillis)}${this.displayRangeSizeInBytes ? ", " + formatSizeInBytes(this.displayRangeSizeInBytes) : ""}${this.displayRangeDuplicatesSizeInBytes ? " (" + formatSizeInBytes(this.displayRangeDuplicatesSizeInBytes) + " in duplicates)" : ""}, ${this.displayRangeNrOfItems} items${this.displayRangeNrOfItems === this.displayRangeNrOfItemsWithSize ? "" : " (" + (this.displayRangeNrOfItems - this.displayRangeNrOfItemsWithSize) + " incomplete)"}`
    const line1Point = {x: rect.x + rect.width, y: rect.y}
    this.drawText(line1, line1Point, "right", "top")

    const line2 = "Click & drag: select range  |  Shift-click: zoom  |  Escape: reset"
    const line2Point = {x: rect.x + rect.width, y: rect.y + this.textHeight}
    this.drawText(line2, line2Point, "right", "top")
  }

  private drawGraph(rect: Rectangle, buckets: Bucket[]) {
    this.drawBars(rect, buckets)
  }

  private drawSelection() {
    if (this.selection.minMillis !== this.selection.maxMillis) {
      const selectionRange = this.getSelectionRange()
      this.context.fillStyle = this.style.selectionColor
      const rect = this.getGraphRect()
      let startX =
        rect.x +
        ((selectionRange.minMillis - this.displayRange.minMillis) /
          (this.displayRange.maxMillis - this.displayRange.minMillis)) *
          rect.width
      let endX =
        rect.x +
        ((selectionRange.maxMillis - this.displayRange.minMillis) /
          (this.displayRange.maxMillis - this.displayRange.minMillis)) *
          rect.width
      startX = Math.min(Math.max(startX, rect.x), rect.x + rect.width)
      endX = Math.min(Math.max(endX, rect.x), rect.x + rect.width)
      this.context.fillRect(startX, rect.y, endX - startX, rect.height)
      const graphRect = this.getGraphRect()
      const sizeText = this.selectionSizeInBytes
        ? formatSizeInBytes(this.selectionSizeInBytes) +
          (this.selectionNrOfItems === this.selectionNrOfItemsWithSize
            ? ""
            : " (" + (this.selectionNrOfItems - this.selectionNrOfItemsWithSize) + " incomplete)")
        : ""
      this.drawTextSize(sizeText, {x: (startX + endX) / 2, y: graphRect.y + graphRect.height / 1.5}, "center", "top")
      if (endX - startX > 15) {
        this.drawTextArrow("<", {x: startX + 2, y: graphRect.y + graphRect.height / 2}, "left", "middle")
        this.drawTextArrow(">", {x: endX - 2, y: graphRect.y + graphRect.height / 2}, "right", "middle")
      }
    }
  }

  private drawCursor() {
    if (this.cursorMillis !== 0) {
      this.context.fillStyle = this.style.axisColor
      const rect = this.getGraphRect()
      let x =
        rect.x +
        ((this.cursorMillis - this.displayRange.minMillis) /
          (this.displayRange.maxMillis - this.displayRange.minMillis)) *
          rect.width
      if (rect.x - this.paddingAxisX <= x && x <= rect.x + rect.width + this.paddingAxisX) {
        this.drawLine({x: x, y: rect.y}, {x: x, y: rect.y + rect.height}, this.style.pointerColor, 1)
      }
    }
  }

  private drawBars(rect: Rectangle, buckets: Bucket[]) {
    let max = 0
    for (const element of buckets) {
      max = Math.max(max, element.count)
    }

    this.context.fillStyle = this.style.barsColor
    const barWidth = rect.width / buckets.length
    for (let i = 0; i < buckets.length; ++i) {
      let bucket = buckets[i]
      if (bucket.count === 0) {
        continue
      }

      const posX = rect.x + i * barWidth + 0.5
      this.context.fillRect(posX, rect.y + rect.height, barWidth - 1, (-rect.height * bucket.count) / max)
    }
  }

  private drawLine(from: PointXY, to: PointXY, color: string, width: number) {
    this.context.strokeStyle = color
    this.context.lineWidth = width
    this.context.beginPath()
    this.context.moveTo(from.x, from.y)
    this.context.lineTo(to.x, to.y)
    this.context.stroke()
  }

  private drawText(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColor
    this.context.font = this.style.font
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextAxis(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorAxis
    this.context.font = this.style.fontAxis
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextSize(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorSize
    this.context.font = this.style.fontSize
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextArrow(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorSize
    this.context.font = this.style.fontSize
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }
}

export default Timeline
