/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {LayerLineParserRegex, LayerType} from "./parserTypes"
import {BoundingBox} from "../common/geo"
import {Feature, GeoJsonProperties} from "geojson"
import {packedTileIdToLevel, packedTileIdToWgs} from "../nds/nds"
import {LngLatToArray} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {isHttpCodeOk} from "../common/httpCodes"
import LogWindow from "../app/logWindow"

export class ParserNdsLive extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parseLine(lineNumber: number, line: string, def: LayerLineParserRegex): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const regex = def.regex.replace("{n}", "([0-9.]+)")
    let from = 0
    while (from < line.length) {
      const match = RegExp(new RegExp(regex)).exec(line.slice(from))
      if (!match) {
        break
      }
      const packedTileId = parseInt(match[1])
      const tileLevel = packedTileIdToLevel(packedTileId)
      const metadata = {
        lineNumber: lineNumber,
        line: line,
        ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
        tileId: packedTileId,
        tileLevel: tileLevel
      }
      const polygon = this.createPolygonFromNdsLiveTile(
        packedTileId,
        def.color,
        metadata,
        def.layerType,
        time,
        sizeInBytes
      )
      if (!polygon) {
        return features
      }
      features.push(polygon)
      from = from + match.index + match[0].length
    }
    return features
  }

  createPolygonFromNdsLiveTile(
    packedTileId: number,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature | undefined {
    if (metadata.httpStatusCode && !isHttpCodeOk(metadata.httpStatusCode)) {
      color = this.modifyColorToErrorState(color)
    }
    const coordinates = packedTileIdToWgs(packedTileId)
    const bounds = BoundingBox.empty().extendToLngLat(coordinates.southWest).extendToLngLat(coordinates.northEast)
    const extendedMetadata = {
      ...metadata,
      ...(time !== undefined && {time: time.getTime()}),
      ...(sizeInBytes !== undefined && {size: sizeInBytes}),
      layer: layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    const feature: Feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            LngLatToArray(coordinates.southWest),
            LngLatToArray(coordinates.northWest),
            LngLatToArray(coordinates.northEast),
            LngLatToArray(coordinates.southEast),
            LngLatToArray(coordinates.southWest)
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: layerType,
        geoHash: bounds.toGeoHash(),
        "fill-opacity": color?.["fill-opacity"],
        "fill-color": color?.["fill-color"],
        "fill-outline-color": color?.["fill-outline-color"]
      }
    }
    return feature
  }
}

export default ParserNdsLive
