/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox} from "../common/geo"
import {Event, TimeRange} from "./timeline"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Feature, GeoJsonProperties} from "geojson"
import MapDataOverlay from "./mapDataOverlay"
import {LayerLineParser, LayerType} from "../parsers/parserTypes"
import ParserGeoJson from "../parsers/parserGeoJson"
import ParserGpx from "../parsers/parserGpx"
import ParserJson from "../parsers/parserJson"
import ParserMapVis from "../parsers/parserMapVis"
import ParserNdsLive from "../parsers/parserNdsLive"
import ParserNavTiles from "../parsers/parserNavTiles"
import ParserCoordinatePairs from "../parsers/parserCoordinatePairs"
import ParserTtp from "../parsers/parserTtp"
import {addAllElements} from "../common/objects"
import DataStore, {DataFileState, FilterMatch, FilterStates, LayerStates, TIMESTAMP_ANYTIME} from "./dataStore"
import {MetadataStore} from "../common/metadata"
import {Settings} from "./settings"
import MapView from "./mapView"
import LogWindow from "./logWindow"
import ParserRoutingApi from "../parsers/parserRoutingApi"

/**
 * The DataCanvas class is the overlay of the map that accepts dropped data and display it using parsers.
 * It relies heavily on on all parser and the map overlay to display the data.
 */
export class DataCanvas {
  dataFileStates: Record<string, DataFileState>
  layerStates: LayerStates
  filterStates: FilterStates
  private readonly map: MapView
  private readonly settings: Settings
  private readonly dataStore: DataStore
  private readonly metadataStore: MetadataStore
  private readonly logWindow: LogWindow
  private readonly mapDataOverlay: MapDataOverlay
  private readonly parseGpx: ParserGpx
  private readonly parseGeoJson: ParserGeoJson
  private readonly parseJson: ParserJson
  private readonly parseTtp: ParserTtp
  private readonly parseMapVis: ParserMapVis
  private readonly parseNdsLive: ParserNdsLive
  private readonly parseNavTiles: ParserNavTiles
  private readonly parseRoutingApi: ParserRoutingApi
  private readonly parseCoordinatePairs: ParserCoordinatePairs
  readonly layerLineParsers: Record<LayerType, LayerLineParser> = {
    MapVisBasicMap: {
      name: "MapVis basic map",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVisBasicMap,
          regex: "/map-display/tile/{z}/{x}/{y}.pbf",
          color: color
        }),
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(139,0,253)",
        "fill-outline-color": "rgb(188,88,191)"
      }
    },

    MapVisIncidents: {
      name: "MapVis incidents",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVisIncidents,
          regex: "/traffic/tile/incidents/{z}/{x}/{y}.pbf",
          color: color
        }),
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,90,255)",
        "fill-outline-color": "rgb(13,67,166)"
      }
    },

    MapVisFlow: {
      name: "MapVis flow",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVisFlow,
          regex: "/traffic/tile/flow/{z}/{x}/{y}.pbf",
          color: color
        }),
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,253,33)",
        "fill-outline-color": "rgb(86,165,126)"
      }
    },

    MapVisHillshade: {
      name: "MapVis hillshade",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVisHillshade,
          regex: "/map-display/tile/hillshade/{z}/{x}/{y}.(png|jpe?g)",
          color: color
        }),
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(4,83,65)",
        "fill-outline-color": "rgb(3,170,120)"
      }
    },

    MapVisSatellite: {
      name: "MapVis satellite",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVisSatellite,
          regex: "/map-display/tile/satellite/{z}/{x}/{y}.(png|jpe?g)",
          color: color
        }),
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(191,113,71)",
        "fill-outline-color": "rgb(234,98,0)"
      }
    },

    MapVis3D: {
      name: "MapVis 3D",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseMapVis.parseLine(lineNumber, line, {
          layerType: LayerType.MapVis3D,
          regex: "/map-display/tile/3d/[A_Za-z-_]+/{z}/{x}/{y}.(glb|gltf)",
          color: color
        }),
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(20,99,110)",
        "fill-outline-color": "rgb(109,205,176)"
      }
    },

    NdsLive: {
      name: "NDS.Live",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseNdsLive.parseLine(lineNumber, line, {
          layerType: LayerType.NdsLive,
          regex: "(?:(?:/nds-\\w+-tiles/)|(?:/umd-tiles/orbis_v[0-9.]+/)).*?tiles/{n}",
          color: color
        }),
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(255,77,0)",
        "fill-outline-color": "rgb(239,74,13)"
      }
    },

    NavTiles: {
      name: "NavTiles",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseNavTiles.parseLine(lineNumber, line, {
          layerType: LayerType.NavTiles,
          regex: "/navkit2navigation/.*?/navigationtile/{x}/{y}",
          color: color
        }),
      color: {
        "fill-opacity": 0.15,
        "fill-color": "rgb(83,76,29)",
        "fill-outline-color": "rgb(248,210,74)"
      }
    },

    RoutingApi: {
      name: "Routing API",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseRoutingApi.parseLine(lineNumber, line, {
          layerType: LayerType.RoutingApi,
          regex: "/routing/calculateRoute/{x1}%2C{y1}%3A{x2}%2C{y2}/",
          color: color
        }),
      color: {
        "fill-opacity": 0.15,
        "fill-color": "rgb(83,29,29)",
        "fill-outline-color": "rgb(248,74,74)"
      }
    },

    CoordinatePairs: {
      name: "Coordinate pairs",
      parseLine: (lineNumber: number, line: string, color: GeoJsonProperties) =>
        this.parseCoordinatePairs.parseLine(lineNumber, line, {
          layerType: LayerType.CoordinatePairs,
          regex:
            "(?:{x}[ \t,;:/]+{y})|(?:(?:latitude|lat|y)[^0-9.]+{y}[^[0-9.]*?(?:longitude|lon|lng|long|x)[^0-9.]+{x})",
          color: color
        }),
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,0,0)",
        "fill-outline-color": "rgb(255,255,255)"
      }
    },

    TTPLocation: {name: "TTP: location", color: undefined},
    TTP1s: {name: "TTP: 1s data", color: undefined},
    TTP100ms: {name: "TTP: 100ms data", color: undefined},
    GPX: {name: "GPX", color: undefined},
    JSON: {name: "JSON/GeoJSON", color: undefined}
  }

  constructor(
    map: MapView,
    settings: Settings,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    dataStore: DataStore
  ) {
    this.dataFileStates = {}
    this.layerStates = {}
    this.filterStates = {
      level: 10,
      match: FilterMatch.GreaterEqual
    }
    this.map = map
    this.settings = settings
    this.logWindow = logWindow
    this.mapDataOverlay = map.createOverlayForTool("canvas", this.getLayers())
    this.parseGpx = new ParserGpx(logWindow, settings, metadataStore)
    this.parseGeoJson = new ParserGeoJson(logWindow, settings, metadataStore)
    this.parseJson = new ParserJson(logWindow, settings, metadataStore)
    this.parseTtp = new ParserTtp(logWindow, settings, metadataStore)
    this.parseMapVis = new ParserMapVis(logWindow, settings, metadataStore)
    this.parseNdsLive = new ParserNdsLive(logWindow, settings, metadataStore)
    this.parseNavTiles = new ParserNavTiles(logWindow, settings, metadataStore)
    this.parseRoutingApi = new ParserRoutingApi(logWindow, settings, metadataStore)
    this.parseCoordinatePairs = new ParserCoordinatePairs(logWindow, settings, metadataStore)
    this.dataStore = dataStore
    this.metadataStore = metadataStore
    this.initLayerStates()
  }

  removeAll() {
    this.dataStore.removeAll()
    this.initLayerStates()
    this.dataFileStates = {}
  }

  /**
   * Handles the drop event.
   * @param dragEvent The event.
   * @param onSuccess To be called once after all files were processed.
   */
  handleDrop(dragEvent: DragEvent, onSuccess: () => void) {
    const files = dragEvent.dataTransfer!.files
    if (files.length > 0) {
      console.debug(`Dropped ${dragEvent.dataTransfer!.files.length} file(s)...`)
      let bounds = BoundingBox.empty()
      let filesProcessed = 0

      for (const file of Array.from(files)) {
        let reader = new FileReader()
        reader.addEventListener("load", (progressEvent) => {
          const features = this.onDrop(file, progressEvent.target!.result as string)

          // If the file was parsed OK, extend the bounds, zoom if needed and redraw.
          if (features) {
            bounds = bounds.extendWithOther(this.dataStore.getBoundsOfDataFile(file))
          } else {
            this.logWindow.setVisible(true)
            this.logWindow.log(
              `\nIMPORTANT: No data was imported from:\n"${file.name}"\nPerhaps the file was already imported?`
            )
          }

          // Check if all files have been processed.
          ++filesProcessed
          if (filesProcessed === files.length) {
            if (this.settings.optionAutoZoom) {
              this.map.zoomToBounds(bounds)
            }
            onSuccess()
          }
        })
        reader.readAsText(file)
      }
    } else {
      let data = dragEvent.dataTransfer!.getData("Text")
      if (data?.trim().length > 0) {
        const features = this.onTextDrop(data)

        // If the file was parsed OK, extend the bounds, zoom if needed and redraw.
        if (features) {
          if (this.settings.optionAutoZoom) {
            this.map.zoomToBounds(this.dataStore.calculateBoundsFromFeatures(features))
          }
          onSuccess()
        } else {
          this.logWindow.log(`Nothing to visualize`)
        }
      }
    }
  }

  /**
   * This effectively clears the conditional layers and resets the time range.
   */
  resetTimeRange() {
    this.mapDataOverlay.replace(this.getLayers())
  }

  /**
   * Sets the time range for the data to be displayed. Times are in milliseconds after epoch
   * as returned by Date.getTime() and property "time" in the GeoJSON features.
   * Important: This must be a lambda and not a method to avoid binding issues.
   * @param timeRange Time range.
   */
  setTimeRange = (timeRange: TimeRange) => {
    // Configure the layers to filter on time.
    const layers = this.getLayers()
    if (timeRange.minMillis < timeRange.maxMillis) {
      layers.forEach((layer) => {
        layer.filter = [
          "all",
          [
            "any",
            ["all", [">=", ["get", "time"], timeRange.minMillis], ["<=", ["get", "time"], timeRange.maxMillis]],
            ["==", ["get", "time"], TIMESTAMP_ANYTIME]
          ],
          ...(layer.filter !== undefined ? [layer.filter] : [])
        ]
      })
    }
    this.mapDataOverlay.replace(layers)

    // Calculate bounding box to zoom in (pretty fast operation).
    let bounds = BoundingBox.empty()
    const features = this.dataStore.getFeaturesFromDataFiles(this.layerStates, this.dataFileStates, this.filterStates)
    features.forEach((feature) => {
      const time = feature.properties?.time
      if (time && timeRange.minMillis <= time && time <= timeRange.maxMillis) {
        const metadataKey = feature.properties?.metadata
        if (metadataKey) {
          const metadata = this.metadataStore.retrieve(metadataKey)
          if (metadata?.bounds) {
            bounds = bounds.extendWithOther(metadata.bounds)
          }
        }
      }
    })

    // Zoom map.
    if (this.settings.optionAutoZoom) {
      this.map.zoomToBounds(bounds)
    }
  }

  updateMapDataFeatures(features: Feature[]) {
    this.mapDataOverlay.setFeatures(features)
  }

  getActiveFeatures() {
    return this.dataStore.getFeaturesFromDataFiles(this.layerStates, this.dataFileStates, this.filterStates)
  }

  getEventsFromFeatures(features: Feature[]) {
    let events: Event[] = []
    features.forEach((feature) => {
      const time = feature.properties?.time as number
      const metadataKey = feature.properties?.metadata
      if (time && metadataKey) {
        const metadata = this.metadataStore.retrieve(metadataKey)
        const sizeInBytes = metadata?.size as number
        const duplicates = metadata?.duplicates as number
        events.push({
          timeMillis: time,
          ...(sizeInBytes !== undefined && {sizeInBytes: sizeInBytes}),
          ...(duplicates !== undefined && {duplicates: duplicates})
        })
      }
    })
    return events
  }

  zoomToBounds() {
    const bounds = this.dataStore.getBoundsOfDataFiles(this.dataFileStates)
    this.map.zoomToBounds(bounds)
  }

  resetCounters() {
    Object.entries(this.layerStates).forEach(([, item]) => {
      item.count = 0
      item.sizeInBytes = 0
    })
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "fill",
        paint: {
          "fill-outline-color": [
            "case",
            ["has", "fill-outline-color"],
            ["get", "fill-outline-color"],
            "rgb(255,255,255)"
          ],
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,198,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.15]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        paint: {
          "line-color": ["case", ["has", "fill-outline-color"], ["get", "fill-outline-color"], "rgb(255,255,255)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 0.66]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(93,93,93)",
          "line-width": ["+", 1, ["case", ["has", "line-width"], ["get", "line-width"], 4]],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["==", ["get", "line-outline"], true]]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0],
          "line-dasharray": [3, 3]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["has", "line-dasharray"]]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["!", ["has", "line-dasharray"]]]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": ["+", 1, ["case", ["has", "circle-radius"], ["get", "circle-radius"], 4]],
          "circle-color": "rgb(0,0,0)"
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": ["case", ["has", "circle-radius"], ["get", "circle-radius"], 6],
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(77,143,75)"]
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      {
        type: "symbol",
        layout: {
          "text-field": ["get", "text"],
          "text-font": ["Noto-Bold"],
          "text-size": 10,
          "text-offset": [0, 0],
          "text-anchor": "center",
          "text-rotate": ["get", "text-rotate"]
        },
        paint: {
          "text-color": ["get", "text-color"]
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      }
    ]
  }

  private countFeatures(features: Feature[], layerType: LayerType) {
    return features
      .map((feature) => {
        if (feature.properties?.metadata) {
          const metadata = this.metadataStore.retrieve(feature.properties.metadata)
          return metadata.layer
        }
        return undefined
      })
      .filter((other) => other == layerType).length
  }

  private onDrop(file: File, contents: string): Feature[] | undefined {
    const fileName = file.name
    let features: Feature[] | undefined

    // Do not import the same file twice.
    if (this.dataStore.exists(file)) {
      return undefined
    }

    // Try all parsers.
    if (fileName.endsWith(".gpx")) {
      features = this.parseGpx.parse(fileName, contents)
      if (features) {
        this.layerStates.GPX.show = true
        this.layerStates.GPX.count += features.length
      }
    } else if (fileName.endsWith(".geo.json") || fileName.endsWith(".geojson")) {
      features = this.parseGeoJson.parse(fileName, contents)
      if (features) {
        this.layerStates.JSON.show = true
        this.layerStates.JSON.count += features.length
      }
    } else if (fileName.endsWith(".json")) {
      features = this.parseJson.parse(fileName, contents)
      if (features) {
        this.layerStates.JSON.show = true
        this.layerStates.JSON.count += features.length
      }
    } else if (fileName.endsWith(".ttp")) {
      features = this.parseTtp.parse(fileName, contents)
      if (features) {
        this.layerStates.TTPLocation.show = true
        this.layerStates.TTPLocation.count += this.countFeatures(features, LayerType.TTPLocation)
        this.layerStates.TTP1s.show = false
        this.layerStates.TTP1s.count += this.countFeatures(features, LayerType.TTP1s)
        this.layerStates.TTP100ms.show = false
        this.layerStates.TTP100ms.count += this.countFeatures(features, LayerType.TTP100ms)
      }
    } else {
      features = this.onFileDropText(contents)
    }
    if (!features || features.length === 0) {
      this.dataStore.remove(fileName)
      delete this.dataFileStates[fileName]
      return undefined
    }

    // Features that cover the exact same feature area should have red border.
    // Exceptions are volatile features that are requested more than <n> minutes apart.
    const volatileLayers = ["MapVisIncidents", "MapVisFlow"]
    const minSecondsBetweenVolatileFeatures = 1.5 * 60
    features.forEach((feature) => {
      const layer = feature.properties?.layer
      const time = feature.properties?.time
      const geoHash = feature.properties?.geoHash
      if (geoHash) {
        // The metadata of all duplicate features needs to be updated.
        const keysToUpdate: Record<string, boolean> = {}
        const duplicates = features.filter((otherFeature) => {
          if (
            feature.properties &&
            otherFeature.properties &&
            otherFeature.properties.layer === layer &&
            otherFeature.properties.geoHash === geoHash &&
            (!volatileLayers.includes(layer) ||
              (time &&
                otherFeature.properties.time &&
                Math.abs(otherFeature.properties.time - time) < minSecondsBetweenVolatileFeatures * 1000))
          ) {
            keysToUpdate[feature.properties.metadata] = true
            keysToUpdate[otherFeature.properties.metadata] = true
            return true
          } else {
            return false
          }
        })
        if (duplicates.length > 1) {
          for (const key in keysToUpdate) {
            const metadata = {
              duplicates: duplicates.length,
              ...this.metadataStore.retrieve(key)
            }
            this.metadataStore.update(key, metadata)
          }
          // Add a red border to the feature to indicate duplicates.
          feature.properties = {
            ...feature.properties,
            "fill-outline-color": "rgb(255,47,0)",
            "line-width": Math.min(6, duplicates.length * 1.5)
          }
        }
      }
    })

    // Add all features to the feature store.
    const dataFile = this.dataStore.addFeaturesFromDataFile(features, file)
    this.addDataFileState(dataFile)
    return features
  }

  private initLayerStates() {
    this.layerStates = {}
    for (const layerType in LayerType) {
      this.layerStates[layerType as keyof typeof LayerType] = {
        show: false,
        count: 0,
        sizeInBytes: 0,
        supportsLevel: !["coordinate-pairs", "ttp", "gpx", "json"].includes(layerType)
      }
    }
  }

  private addDataFileState(dataFile: string, visible = true) {
    this.dataFileStates[dataFile] = {show: visible}
  }

  private onTextDrop(text: string): Feature[] | undefined {
    // Try text parser.
    text = text.trim()
    let features = this.parseGeoJson.parseString(text)
    if (!features) {
      // Or try any other parser.
      features = this.onFileDropText(text)
      if (!features) {
        this.logWindow.log(`Found nothing to visualize`)
        return
      }
    }

    // Add all features to the feature store.
    const dataFile = this.dataStore.addFeaturesFromDataFile(features)
    this.addDataFileState(dataFile)
    return features.length > 0 ? features : undefined
  }

  private onFileDropText(contents: string): Feature[] | undefined {
    let allFeatures: Feature[] = []
    contents.split("\n").forEach((line, lineNumber) => {
      const featurePerLine = this.parseSingleTextLine(lineNumber + 1, line)
      if (featurePerLine.length > 0) {
        addAllElements(allFeatures, featurePerLine)
      }
    })

    if (allFeatures.length > 0) {
      this.logWindow.log(`${allFeatures.length} items were found, of which:`)
      for (const layerType in LayerType) {
        const layerState = this.layerStates[layerType as keyof typeof LayerType]
        const layerLineParser = this.layerLineParsers[layerType as keyof typeof LayerType]
        if (layerState.count > 0) {
          this.logWindow.log(`  ${layerState.count} ${layerLineParser.name} items`)
        }
      }
    }
    return allFeatures.length > 0 ? allFeatures : undefined
  }

  private parseSingleTextLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []

    // Check if we need to skip this line.
    line = line.trim()
    if (this.settings.optionSkipComments) {
      if (/^\s*#/.test(line)) {
        return features
      }
    }

    // Execute the parsers on the line.
    for (const layerType in LayerType) {
      const layerState = this.layerStates[layerType as keyof typeof LayerType]
      const layerLineParser = this.layerLineParsers[layerType as keyof typeof LayerType]
      if (layerLineParser.parseLine) {
        features = layerLineParser.parseLine(lineNumber, line, layerLineParser.color!)
        if (features && features.length > 0) {
          layerState.show = true
          layerState.count += features.length
          layerState.sizeInBytes += features
            .map((feature) => {
              const metadataKey = feature.properties?.metadata
              if (metadataKey) {
                const metadata = this.metadataStore.retrieve(metadataKey)
                return metadata?.size || 0
              }
            })
            .reduce((a, b) => a + b, 0)
          return features
        }
      }
    }
    return features
  }
}

export default DataCanvas
