/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import MenuBar from "./menuBar"

export class HtmlWindow {
  protected readonly htmlElement: HTMLElement

  constructor(htmlElementId: string) {
    const element = document.getElementById(htmlElementId)
    if (!element) {
      throw new Error(`WhatsNewWindow: could not find element with id '${htmlElementId}'`)
    }
    this.htmlElement = element
    this.setVisible(this.isVisible())
  }

  setVisible(show: boolean) {
    this.htmlElement.style.display = show ? "block" : "none"
    MenuBar.updateMenuItemToggle(`${this.htmlElement.id}-menuitem`, show)
  }

  isVisible() {
    return this.htmlElement.style.display !== "none"
  }
}

export default HtmlWindow
