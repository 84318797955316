/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat, LngLatToArray} from "../common/wgs84"
import {Feature, GeoJsonProperties, Point, Polygon} from "geojson"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {BoundingBox, PointXY, pointXYToLngLat} from "../common/geo"
import {LayerType} from "./parserTypes"
import {isHttpCodeOk} from "../common/httpCodes"
import {convertMillisToDateTime, getDateTimeFromTimestamp} from "../common/datetime"
import LogWindow from "../app/logWindow"

/**
 * Parser is the base class for all parsers. It contains some utility functions used for parsers.
 */
export abstract class Parser {
  public static readonly SIZE_EXPECTED_BUT_NOT_FOUND = -1 // Return in field `size` if size was expected but not found.

  protected readonly logWindow: LogWindow
  protected readonly settings: Settings
  protected readonly metadataStore: MetadataStore

  private readonly regexIsoTimestamp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|(?:[+-](?:\d)+))/

  protected constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    this.logWindow = logWindow
    this.settings = settings
    this.metadataStore = metadataStore
  }

  protected createRectangle(
    southWest: LngLat,
    northEast: LngLat,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature<Polygon> {
    const bounds = new BoundingBox(southWest, northEast)
    const extendedMetadata = {
      ...metadata,
      ...(time && {time: time.getTime()}),
      ...{size: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    const northWest: LngLat = {lng: southWest.lng, lat: northEast.lat}
    const southEast: LngLat = {lng: northEast.lng, lat: southWest.lat}
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            LngLatToArray(southWest),
            LngLatToArray(northWest),
            LngLatToArray(northEast),
            LngLatToArray(southEast),
            LngLatToArray(southWest)
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: layerType,
        geoHash: bounds.toGeoHash(),
        "fill-opacity": color?.["fill-opacity"],
        "fill-color": color?.["fill-color"],
        "fill-outline-color": color?.["fill-outline-color"]
      }
    }
  }

  protected createPointFromCoordinates(
    coordinate: PointXY,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature<Point> {
    if (metadata.httpStatusCode && !isHttpCodeOk(metadata.httpStatusCode)) {
      color = this.modifyColorToErrorState(color)
    }
    const bounds = BoundingBox.fromLngLat(pointXYToLngLat(coordinate))
    const extendedMetadata = {
      ...metadata,
      layer: layerType,
      ...(time && {time: time.getTime()}),
      ...{size: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordinate.y, coordinate.x]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: layerType,
        geoHash: bounds.toGeoHash(),
        "circle-radius": 10,
        "circle-color": color
      }
    }
  }

  protected modifyColorToErrorState(color: GeoJsonProperties) {
    return {
      ...color,
      "fill-opacity": 0.8,
      "fill-color": "rgb(255,0,0)",
      "fill-outline-color": "rgb(255,0,0)",
      "line-width": 2
    }
  }

  /**
   * Get a date/time from a line, in various formats.
   * @param line Input line.
   * @returns Date object or undefined if no date was found.
   */
  protected getDateTimeFromString(line: string) {
    // Try regular formats.
    const dateTime = getDateTimeFromTimestamp(line)
    if (dateTime) {
      return dateTime
    }

    // This matches, for example, Scalyr exported TXT and CSV files.
    const regexTimestamp = /["']timestamp[a-z"': ]*?:.*?([0-9.]+)/
    let match = regexTimestamp.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return convertMillisToDateTime(millisOrSeconds)
    }

    // This matches any time in seconds after epoch in a bit more fuzzy way. It assumes a number
    // which can be seconds or milliseconds after epoch. It auto-detects which one it is.
    const regexTimeAsMillisOrSeconds = /time[a-z"': ]*?:.*?([0-9.]+)/
    match = regexTimeAsMillisOrSeconds.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return convertMillisToDateTime(millisOrSeconds)
    }
    return undefined
  }

  protected getSizeInBytesFromLine(line: string): number | undefined {
    // Format: timestamp;httpStatus;responseSizeInBytes;durationInMilliseconds;url
    const regexHttpLog = new RegExp(`${this.regexIsoTimestamp.source}\\s*;\\s*-?\\d+\\s*;\\s*(\\d+)\\s*;`)
    let match = regexHttpLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexMitmLog = /;(\d+);[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexScalyrLog = /body_bytes_sent[^\d]*(\d+)/
    match = regexScalyrLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexOtherSizes = /(?:(?:size)|(?:bytes))\s*[:=]\s*(\d+)/
    match = regexOtherSizes.exec(line)
    if (match) {
      return parseInt(match[1])
    }
    return undefined
  }

  protected getHttpStatusCodeString(line: string) {
    const validCodeOrUndefined = (code: number) => (100 <= code && code <= 599 ? code : undefined)

    const regexHttpLog = new RegExp(`${this.regexIsoTimestamp.source}\\s*;\\s*-?(\\d+)\\s*;`)
    let match = regexHttpLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexMitmLog = /;\s*(\d+)\s*;[^;]*;[^;]*;[^;]*;[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexStatusField = /status[A-Za-z-_]*\s*["']?\s*[:=]\s*['"]?(\d+)/
    match = regexStatusField.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }
    return undefined
  }
}

export default Parser
