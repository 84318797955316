/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Point as MapLibrePoint} from "maplibre-gl"
import DataSelectorTool from "../tools/dataSelectorTool"
import RouteCreatorJsonTool from "../tools/routeCreatorJsonTool"
import RouteCreatorGpxTool from "../tools/routeCreatorGpxTool"
import DistanceCalculatorTool from "../tools/distanceCalculatorTool"
import TileEditorTool from "../tools/tileEditorTool"
import {Tool} from "../tools/tool"
import {LngLat} from "../common/wgs84"
import MapView from "./mapView"
import DataCanvas from "./dataCanvas"
import Timeline from "./timeline"
import MenuBar from "./menuBar"
import {Tools} from "./appTypes"
import {Settings} from "./settings"
import {formatNumberAsPowers, formatSizeInBytes} from "../common/objects"
import {DataFileInfo, DataStore, FilterMatch} from "./dataStore"
import {MetadataStore} from "../common/metadata"
import InvalidApiKey from "../exceptions/invalidApiKey"
import {GenericMenuItem, LayerMenuItem, MenuItem, separatorMenuItem} from "./menuTypes"
import {LayerType} from "../parsers/parserTypes"
import {HelpWindow} from "./helpWindow"
import {APPLICATION_VERSION} from "../common/version"
import {
  getDefaultHttpOverheadSizeInBytes,
  KEY_HTTP_OVERHEAD_SIZE_IN_BYTES,
  setDefaultHttpOverheadSizeInBytes
} from "../common/httpCodes"
import WhatsNewWindow from "./whatsNewWindow"
import InspectorWindow from "./inspectorWindow"
import LogWindow from "./logWindow"
import {Toolbox} from "./toolbox"

/**
 * This class defines the application. It supports an exception handler to catch exceptions during
 * execution.
 */
export class App {
  public static readonly KEY_API_KEY_TOMTOM = "api-key-tomtom"
  private readonly KEY_PREVIOUS_APPLICATION_VERSION = "previous-application-version"

  /**
   * Below are some HTML element IDs used in the application.
   * The application uses some cnmventions that modules can rely
   * on, such as:
   *
   * "some-id" - this is a HTML element ID.
   * "some-id-menuitem" - this is the ID of the menu item related to "some-id", often used to show a on/off toggle
   * "some-id-dropdown" - this is the ID of the drop down menu item related to "some-id".
   * "some-id-content" - this is the ID of an HTML text placeholder for "some-id".
   * "some-id-color/text/status" - these are the IDs of layer HTML elements in the layers list.
   */
  private readonly htmlIdHelpWindow = "help-window"
  private readonly htmlIdInspectorWindow = "inspector-window"
  private readonly htmlIdLogWindow = "log-window"
  private readonly htmlIdMenuBar = "menu-bar"
  private readonly htmlIdMap = "map"
  private readonly htmlIdWhatsNewWindow = "whats-new-window"
  private readonly htmlIdOptionsList = "options-list"
  private readonly htmlIdLayersList = "layers-list"
  private readonly htmlIdDataFilesList = "data-files-list"
  private readonly htmlIdTimelineWindow = "timeline-window"

  private readonly settings: Settings
  private readonly canvas: DataCanvas
  private readonly map: MapView
  private readonly timeline: Timeline
  private readonly metadataStore: MetadataStore
  private readonly dataStore: DataStore
  private readonly tools: Tools
  private readonly toolbox: Toolbox
  private readonly menuBar: MenuBar
  private readonly helpWindow: HelpWindow
  private readonly whatsNewWindow: WhatsNewWindow
  private readonly inspectorWindow: InspectorWindow
  private readonly logWindow: LogWindow

  private currentTool: Tool
  private keyboardShortcutsEnabled = true

  constructor(applicationVersion: string, apiKey: string, document: Document) {
    window.onerror = this.handleException
    console.debug(`Created application, version ${applicationVersion}`)

    this.helpWindow = new HelpWindow(this.htmlIdHelpWindow)
    this.helpWindow.setVisible(false)

    this.whatsNewWindow = new WhatsNewWindow(this.htmlIdWhatsNewWindow)
    this.whatsNewWindow.setVisible(false)

    this.inspectorWindow = new InspectorWindow(this.htmlIdInspectorWindow)
    this.inspectorWindow.setVisible(false)

    this.logWindow = new LogWindow(this.htmlIdLogWindow)
    this.logWindow.setVisible(false)

    this.settings = new Settings()
    this.map = new MapView("map", this.logWindow, apiKey, this.onClick)
    this.metadataStore = new MetadataStore()
    this.dataStore = new DataStore(this.metadataStore)
    this.canvas = new DataCanvas(this.map, this.settings, this.logWindow, this.metadataStore, this.dataStore)
    this.tools = {
      dataSelector: new DataSelectorTool(
        this.map,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        this.map.queryFeatures
      ),
      distanceCalculator: new DistanceCalculatorTool(
        this.map,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore
      ),
      routeCreatorJson: new RouteCreatorJsonTool(
        this.map,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        apiKey
      ),
      routeCreatorGpx: new RouteCreatorGpxTool(
        this.map,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        apiKey
      ),
      tileEditor: new TileEditorTool(
        this.map,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        this.map.queryTileUrl
      )
    }
    this.currentTool = this.tools.dataSelector
    this.timeline = new Timeline(document.getElementById("timeline-window")!, this.canvas.setTimeRange)

    const viewMenuDefinition = this.setupViewMenu()
    const othersMenuDefinition = this.setupOthersMenu()
    this.setupKeyboardListeners([viewMenuDefinition, othersMenuDefinition].flat())
    this.setupOtherListeners()

    const clearButton = document.getElementById("button-clear-map")!
    clearButton.addEventListener("click", () => this.clearMap())
    this.createDataFilesList()
    this.createLayersList()
    this.updateOptionTileLevels()

    this.toolbox = new Toolbox(this.selectTool, this.toggleHelpWindow)
    this.toolbox.selectInitialTool()

    this.menuBar = new MenuBar(
      this.map.getMapsMenu(),
      viewMenuDefinition,
      this.setupLayersMenu(),
      othersMenuDefinition,
      this.map.selectMap
    )
    window.onclick = (e) => this.menuBar.onWindowClick(e)

    this.updateOptionsStatus("auto-zoom", this.settings.optionAutoZoom)
    MenuBar.updateMenuItemToggle("tile-grid-menuitem", this.map.showTileGrid())
    MenuBar.updateMenuItemToggle("urban-sections-menuitem", this.settings.optionUrbanSections)
    MenuBar.updateMenuItemToggle("skip-comments-menuitem", this.settings.optionSkipComments)

    // Automatically show help/what's new if this is a new version.
    let previousApplicationVersion = localStorage.getItem(this.KEY_PREVIOUS_APPLICATION_VERSION)
    if (previousApplicationVersion !== APPLICATION_VERSION) {
      localStorage.setItem(this.KEY_PREVIOUS_APPLICATION_VERSION, APPLICATION_VERSION)
      this.whatsNewWindow.setVisible(true)
    }

    Object.entries(this.canvas.layerStates).forEach(([key, item]) => {
      this.updateOptionsStatus(key, item.show)
    })

    this.logWindow.log(
      `<h1>Welcome TomTom Map Data Visualizer ${applicationVersion}!</h1>
Drop a file, or drag text into the the map to visualize its content. This can be TTP, GPX, JSON, GeoJSON, CSV or another text format.

The application will try to recognize the content and display it on the map. You can switch data layers on and off in the panels. Keyboard shortcuts are listed in the menu. 

<em>Have fun!</em>`,
      true
    )
    ;[
      this.htmlIdMap,
      this.htmlIdMenuBar,
      this.htmlIdLogWindow,
      this.htmlIdInspectorWindow,
      this.htmlIdHelpWindow,
      this.htmlIdWhatsNewWindow,
      this.htmlIdOptionsList,
      this.htmlIdLayersList,
      this.htmlIdDataFilesList,
      this.htmlIdTimelineWindow
    ].forEach((id) => {
      const dropArea = document.getElementById(id)
      if (!dropArea) {
        throw new Error(`Drop area ${id} is missing`)
      }
      dropArea.addEventListener("dragover", (dragEvent) => {
        dragEvent.preventDefault()
        dropArea.style.border = "2px solid rgb(255,0,0)"
      })

      dropArea.addEventListener("dragleave", (dragEvent) => {
        dragEvent.preventDefault()
        dropArea.style.border = "0px"
      })

      dropArea.addEventListener("drop", (dragEvent) => {
        this.showProgressWindow("Processing data, please wait...")
        dragEvent.preventDefault()
        dropArea.style.border = "0px"
        this.canvas.handleDrop(dragEvent, () => {
          this.clearAllToolDataExceptDistanceCalculator()
          this.updateLayersList()
          this.createDataFilesList()
          this.draw()
          this.hideProgressWindow()
        })
      })
    })
    const selectFilesButton = document.getElementById("button-select-files")!
    selectFilesButton.addEventListener("click", () => this.selectFiles())
  }

  showProgressWindow(message: string) {
    const loadingWindow = document.getElementById("progress-window")!
    const loadingText = document.getElementById("progress-window-content")!
    loadingText.innerText = message
    loadingWindow.style.display = "block"
  }

  hideProgressWindow() {
    const loadingWindow = document.getElementById("progress-window")!
    loadingWindow.style.display = "none"
  }

  toggleShowTileGrid() {
    this.map.toggleShowTileGrid()
    MenuBar.updateMenuItemToggle("tile-grid-menuitem", this.map.showTileGrid())
  }

  private selectFiles() {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".ttp,.gpx,.json,.geojson,.csv,.txt,.log,.http,.xml,.output,.diff"

    input.onchange = (event: Event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        this.showProgressWindow("Processing data, please wait...")
        const dataTransfer = new DataTransfer()
        for (const element of files) {
          dataTransfer.items.add(element)
        }
        const dragEvent = new DragEvent("drop", {
          dataTransfer: dataTransfer,
          bubbles: true,
          cancelable: true
        })
        // Simulate drop event for the selected files.
        this.canvas.handleDrop(dragEvent, () => {
          this.clearAllToolDataExceptDistanceCalculator()
          this.updateLayersList()
          this.createDataFilesList()
          this.draw()
          this.hideProgressWindow()
        })
      }
    }
    // Open file selector dialog.
    input.click()
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param location Location on map.
   * @param point Screen map location.
   */
  private onClick = (location: LngLat, point: MapLibrePoint) => {
    this.currentTool.onClick(location, point)
  }

  /**
   * Select a tool. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   * @param id Tool to select.
   */
  private selectTool = (id: keyof Tools) => {
    this.currentTool = this.tools[id]
    if (this.tools[id].help) {
      this.logWindow.log(`Selected tool: ${this.tools[id].name}\n${this.tools[id].help}`, false)
    }
  }

  /**
   * Toggle help window. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   */
  private toggleHelpWindow = () => {
    this.helpWindow.setVisible(!this.helpWindow.isVisible())
    if (this.helpWindow.isVisible()) {
      this.whatsNewWindow.setVisible(false)
    }
  }

  private toggleLayer(layerType: LayerType) {
    const layerState = this.canvas.layerStates[layerType]
    layerState.show = !layerState.show
    this.updateOptionsStatus(layerType as string, layerState.show)
    this.draw()
  }

  private showAllLayers(show: boolean) {
    Object.entries(this.canvas.layerStates).forEach(([key, layerState]) => {
      layerState.show = show
    })
    this.updateLayersList()
    this.draw()
  }

  private toggleOptionTileLevelKeyboard(tileLevel: number) {
    switch (this.canvas.filterStates.match) {
      case FilterMatch.All:
        if (this.canvas.filterStates.level === tileLevel) {
          this.canvas.filterStates.match = FilterMatch.Equal
        } else {
          this.canvas.filterStates.level = tileLevel
        }
        break
      case FilterMatch.Equal:
        if (this.canvas.filterStates.level === tileLevel) {
          this.canvas.filterStates.match = FilterMatch.LessEqual
        } else {
          this.canvas.filterStates.level = tileLevel
        }
        break
      case FilterMatch.LessEqual:
        if (this.canvas.filterStates.level === tileLevel) {
          this.canvas.filterStates.match = FilterMatch.GreaterEqual
        } else {
          this.canvas.filterStates.level = tileLevel
        }
        break
      case FilterMatch.GreaterEqual:
        if (this.canvas.filterStates.level === tileLevel) {
          this.canvas.filterStates.match = FilterMatch.All
        } else {
          this.canvas.filterStates.level = tileLevel
        }
        break
    }
    this.updateOptionTileLevels()
    this.draw()
  }

  private toggleOptionSkipComments() {
    this.settings.optionSkipComments = !this.settings.optionSkipComments
    MenuBar.updateMenuItemToggle("skip-comments-menuitem", this.settings.optionSkipComments)
  }

  private toggleOptionUrbanSections() {
    this.settings.optionUrbanSections = !this.settings.optionUrbanSections
    MenuBar.updateMenuItemToggle("urban-sections-menuitem", this.settings.optionUrbanSections)
  }

  private updateOptionTileLevels() {
    const filterStates = this.canvas.filterStates
    const cellFilterAll = document.getElementById("filter-all")!
    cellFilterAll.classList.remove("off", "on")

    const cellFilterEq = document.getElementById("filter-eq")!
    cellFilterEq.classList.remove("off", "on")

    const cellFilterLe = document.getElementById("filter-le")!
    cellFilterLe.classList.remove("off", "on")

    const cellFilterGe = document.getElementById("filter-ge")!
    cellFilterGe.classList.remove("off", "on")

    for (let i = 8; i <= 17; i++) {
      const cell = document.getElementById(`filter-level-${i}`)!
      cell.classList.remove("off", "on")
    }
    const cellLevel = document.getElementById(`filter-level-${filterStates.level}`)!
    cellLevel.classList.remove("off", "on")
    cellLevel.classList.add("on")

    let cell: HTMLElement = cellFilterAll
    switch (filterStates.match) {
      case FilterMatch.LessEqual:
        cell = cellFilterLe
        break
      case FilterMatch.Equal:
        cell = cellFilterEq
        break

      case FilterMatch.GreaterEqual:
        cell = cellFilterGe
        break
    }
    cell.classList.add("on")
  }

  private updateOptionsStatus(id: string, value: boolean) {
    MenuBar.updateMenuItemToggle(`${id}-menuitem`, value)
    const cell = document.getElementById(`${id}-status`)
    if (!cell) {
      console.error(`Cannot find HTML element with id: ${id}`)
      return
    }
    cell.innerText = value ? "ON" : "OFF"
    cell.classList.remove("off", "on")
    cell.classList.add(value ? "on" : "off")
  }

  private showLayerSizeInBytes(layerType: LayerType) {
    return !(
      layerType == LayerType.TTPLocation ||
      layerType == LayerType.TTP1s ||
      layerType == LayerType.TTP100ms ||
      layerType == LayerType.GPX ||
      layerType == LayerType.JSON ||
      layerType == LayerType.CoordinatePairs
    )
  }

  private formatLayerSize(layerType: LayerType, sizeInBytes: number, sizeInItems: number) {
    if (this.showLayerSizeInBytes(layerType)) {
      return sizeInBytes ? formatSizeInBytes(sizeInBytes, 1, 1) : "-"
    } else {
      return sizeInItems ? `(${formatNumberAsPowers(sizeInItems, 2, 7)})` : "-"
    }
  }

  private getHttpOverhead() {
    return `Overhead=${formatSizeInBytes(getDefaultHttpOverheadSizeInBytes())}/req`
  }

  private createLayersList() {
    const layersList = document.getElementById("layers-list")!
    layersList.innerHTML = ""

    const table = document.createElement("table")
    const title = document.createElement("td")
    const row = document.createElement("tr")
    title.textContent = "Layers:"
    title.style.lineHeight = "20px"
    title.style.fontWeight = "bold"
    row.appendChild(title)
    const overhead = document.createElement("td")
    overhead.id = "layers-overhead"
    overhead.textContent = this.getHttpOverhead()
    overhead.style.lineHeight = "20px"
    overhead.style.fontWeight = "regular"
    row.appendChild(overhead)
    table.appendChild(row)

    for (const layerType in LayerType) {
      const layerState = this.canvas.layerStates[layerType as keyof typeof LayerType]
      const layerLineParser = this.canvas.layerLineParsers[layerType as keyof typeof LayerType]

      const row = document.createElement("tr")
      const columnColor = document.createElement("td")
      columnColor.id = `${layerType}-color`
      columnColor.className = "options-color"
      if (!this.canvas.layerLineParsers[layerType as keyof typeof LayerType].color) {
        columnColor.innerText = "MIX"
      }
      // Properties for backgroundColor and border will be added later.
      row.appendChild(columnColor)

      const columnName = document.createElement("td")
      columnName.id = `${layerType}-text`
      columnName.className = "options-text"
      columnName.textContent = layerLineParser.name
      row.appendChild(columnName)

      const columnSize = document.createElement("td")
      columnSize.id = `${layerType}-size`
      columnSize.className = "options-size"
      columnSize.textContent = this.formatLayerSize(layerType as LayerType, 0, 0)
      row.appendChild(columnSize)

      const columnStatus = document.createElement("td")
      columnStatus.className = "options-status"
      columnStatus.id = `${layerType}-status`
      // Properties for innerText and on/off will be added later.

      columnStatus.innerText = layerState.show ? "ON" : "OFF"
      columnStatus.classList.remove("off", "on")
      columnStatus.classList.add(layerState.show ? "on" : "off")

      row.appendChild(columnStatus)
      table.appendChild(row)
    }
    layersList.appendChild(table)

    // Add missing properties.
    this.updateLayersList()
  }

  private updateLayersList() {
    const overhead = document.getElementById("layers-overhead")!
    overhead.textContent = this.getHttpOverhead()

    for (const layerType in LayerType) {
      const layerState = this.canvas.layerStates[layerType as keyof typeof LayerType]
      MenuBar.updateMenuItemToggle(`${layerType}-menuitem`, layerState.show)
      const layerLineParser = this.canvas.layerLineParsers[layerType as keyof typeof LayerType]
      const columnColor = document.getElementById(`${layerType}-color`)!
      if (layerLineParser.color) {
        columnColor.style.backgroundColor = layerLineParser.color["fill-color"]
        columnColor.style.border = `1px solid ${layerLineParser.color["fill-outline-color"]}`
      }

      const columnSize = document.getElementById(`${layerType}-size`)!
      columnSize.innerText = this.formatLayerSize(
        layerType as LayerType,
        layerState.sizeInBytes + layerState.count * getDefaultHttpOverheadSizeInBytes(),
        layerState.count
      )

      const columnStatus = document.getElementById(`${layerType}-status`)!
      columnStatus.innerText = layerState.show ? "ON" : "OFF"
      columnStatus.classList.remove("off", "on")
      columnStatus.classList.add(layerState.show ? "on" : "off")
    }
  }

  private createDataFilesList() {
    const toggle = (dataFileInfo: DataFileInfo) => {
      const newValue = !this.canvas.dataFileStates[dataFileInfo.id].show
      this.canvas.dataFileStates[dataFileInfo.id].show = newValue
      this.updateOptionsStatus(dataFileInfo.id, newValue)
      if (newValue && this.settings.optionAutoZoom) {
        this.canvas.zoomToBounds()
      }
      this.draw()
    }

    const dataFilesListHeader = document.getElementById("data-files-list-header")!
    const title = document.createElement("tr")
    const sizeInBytes = this.totalSizeInBytes()
    title.textContent = `Data files${sizeInBytes > 999 ? " (" + formatSizeInBytes(sizeInBytes) + " in memory)" : ""}:`
    title.style.lineHeight = "20px"
    title.style.fontWeight = "bold"
    dataFilesListHeader.innerHTML = ""
    dataFilesListHeader.appendChild(title)

    const dataFilesListContent = document.getElementById("data-files-list-content")!
    dataFilesListContent.innerHTML = ""

    const dataFileInfoList: DataFileInfo[] = this.dataStore.getDataFileInfoList()
    const table = document.createElement("table")

    dataFileInfoList.forEach((dataFileInfo) => {
      const row = document.createElement("tr")
      const columnName = document.createElement("td")
      columnName.className = "options-text longer"
      columnName.textContent = dataFileInfo.name
      columnName.onclick = () => toggle(dataFileInfo)
      row.appendChild(columnName)

      const columnStatus = document.createElement("td")
      columnStatus.className = "options-status"
      columnStatus.id = `${dataFileInfo.id}-status`
      columnStatus.onclick = () => toggle(dataFileInfo)
      row.appendChild(columnStatus)
      table.appendChild(row)
    })
    dataFilesListContent.appendChild(table)
    dataFileInfoList.forEach((layerInfo) =>
      this.updateOptionsStatus(layerInfo.id, this.canvas.dataFileStates[layerInfo.id].show)
    )
  }

  private toggleAutoZoom() {
    this.settings.optionAutoZoom = !this.settings.optionAutoZoom
    this.updateOptionsStatus("auto-zoom", this.settings.optionAutoZoom)
    if (this.settings.optionAutoZoom) {
      this.canvas.zoomToBounds()
    }
  }

  private setupKeyboardListeners(menuDefinitions: MenuItem[]) {
    document.body.addEventListener("keydown", (keyboardEvent) => {
      if (!this.keyboardShortcutsEnabled) {
        return
      }
      if (keyboardEvent.key >= "0" && keyboardEvent.key <= "7") {
        this.toggleOptionTileLevelKeyboard(parseInt(keyboardEvent.key) + 10)
      } else if (keyboardEvent.key >= "8" && keyboardEvent.key <= "9") {
        this.toggleOptionTileLevelKeyboard(parseInt(keyboardEvent.key))
      } else {
        const menuItem = menuDefinitions.find(
          (item) =>
            "shortcut" in item && (keyboardEvent.key === `${item.key}` || keyboardEvent.code === `Key${item.shortcut}`)
        )
        if (menuItem && "action" in menuItem) {
          menuItem.action()
        }
      }
    })
  }

  private setupOtherListeners() {
    ;["auto-zoom-text", "auto-zoom-status"].forEach((id) => {
      document.getElementById(id)?.addEventListener("click", (event) => {
        this.toggleAutoZoom()
      })
    })
    ;["toggle-all-text", "toggle-all-status"].forEach((id) => {
      document.getElementById(id)?.addEventListener("click", () => {
        const show = Object.entries(this.canvas.layerStates)
          .map(([key, layerState]) => layerState.show)
          .reduce((acc, show) => acc || show, false)
        this.showAllLayers(!show)
      })
    })
    document.getElementById("toggle-all-status")!.innerText = "CLICK"
    ;["filter-all", "filter-eq", "filter-le", "filter-ge"].forEach((id) => {
      document.getElementById(id)?.addEventListener("click", () => {
        switch (id) {
          case "filter-all":
            this.canvas.filterStates.match = FilterMatch.All
            break
          case "filter-eq":
            this.canvas.filterStates.match = FilterMatch.Equal
            break
          case "filter-le":
            this.canvas.filterStates.match = FilterMatch.LessEqual
            break
          case "filter-ge":
            this.canvas.filterStates.match = FilterMatch.GreaterEqual
            break
        }
        this.updateOptionTileLevels()
        this.draw()
      })
    })
    for (let i = 8; i <= 17; i++) {
      document.getElementById(`filter-level-${i}`)?.addEventListener("click", () => {
        this.toggleOptionTileLevelKeyboard(i)
      })
    }

    const closeButtons = document.querySelectorAll(".close-button")
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        const windowElement = closeButton.closest(".window") as HTMLElement
        windowElement.style.display = "none"
      })
    })
  }

  private totalSizeInBytes() {
    return this.dataStore.totalSizeInBytes()
  }

  /**
   * Remove local storage, incl. API key.
   */
  private clearLocalStorage() {
    localStorage.removeItem(App.KEY_API_KEY_TOMTOM)
    localStorage.removeItem(this.KEY_PREVIOUS_APPLICATION_VERSION)
    localStorage.removeItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
    window.location.reload()
  }

  private enterDefaultHttpOverheadSize() {
    const submitDialog = () => {
      const input = document.getElementById("data-input-value") as HTMLInputElement
      const number = parseInt(input.value)
      if (number !== undefined && !isNaN(number) && number >= 0 && number <= 16 * 1024) {
        setDefaultHttpOverheadSizeInBytes(number)
        document.body.removeEventListener("keydown", handleKeydown)
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.updateLayersList()
      } else {
        alert("Please enter a valid number between 0-16384.")
      }
    }

    const cancelDialog = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      const dialog = document.getElementById("data-input")!
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = document.getElementById("data-input")!
    dialog.style.display = "block"
    const value = document.getElementById("data-input-value")! as HTMLInputElement
    value.value = getDefaultHttpOverheadSizeInBytes().toString()
    value.focus()
    value.select()
    document.body.addEventListener("keydown", handleKeydown)
    document.getElementById("data-input-submit")!.addEventListener("click", () => submitDialog())
    document.getElementById("data-input-cancel")!.addEventListener("click", () => cancelDialog())
  }

  private yesNoDialog(htmlText: string, onYes: () => void, onNo: () => void) {
    this.keyboardShortcutsEnabled = false
    const text = document.getElementById("yes-no-dialog-text")!
    text.innerHTML = htmlText
    const dialog = document.getElementById("yes-no-dialog")!
    dialog.style.display = "block"
    document.getElementById("yes-no-dialog-yes")!.addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onYes()
    })
    document.getElementById("yes-no-dialog-no")!.addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onNo()
    })
  }

  private createGpxFromRoutingResponseFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json, .txt"
    input.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.tools.routeCreatorGpx.createGpxFromRoutingResponse(file.name, content)
        }
        reader.readAsText(file)
      }
    }
    input.click()
  }

  private createRoutesFromJsonFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json"
    input.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.tools.routeCreatorJson.createRoutesFromJson(file.name, content)
        }
        reader.readAsText(file)
      }
    }
    input.click()
  }

  private clearAllToolDataExceptDistanceCalculator() {
    this.timeline.resetDisplayRange()
    for (let toolKey in this.tools) {
      const key = toolKey as keyof Tools
      if (key !== "distanceCalculator") {
        this.tools[key].clearToolData()
      }
    }
    this.tools["distanceCalculator"].prepareNextMeasurement()
    this.helpWindow.setVisible(false)
    this.whatsNewWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
  }

  private clearMap() {
    this.canvas.removeAll()
    this.tools["distanceCalculator"].clearToolData()
    this.clearAllToolDataExceptDistanceCalculator()
    this.createDataFilesList()
    this.updateLayersList()
    this.draw()
  }

  private undo() {
    this.logWindow.log("Undo last action")
    this.currentTool.undo && this.currentTool.undo()
    this.draw()
  }

  private draw() {
    const features = this.canvas.getActiveFeatures()
    this.canvas.updateMapDataFeatures(features)
    this.timeline.replaceAllEvents(this.canvas.getEventsFromFeatures(features))
  }

  /**
   * Exception handler. Important: this needs to be a lambda and not a regular function to avoid
   * binding issues.
   * @param message Message.
   * @param source Source.
   * @param line Line.
   * @param column Column.
   * @param error Error.
   */
  private handleException = (
    message: string | Event,
    source: string | undefined,
    line: number | undefined,
    column: number | undefined,
    error: Error | undefined
  ): boolean => {
    console.error(
      `Exception: ${JSON.stringify(message)} in: ${source}, line ${line}, column ${column}\nerror: ${JSON.stringify(error)}`
    )
    if (error instanceof InvalidApiKey) {
      this.clearLocalStorage()
      return true
    }
    // Return a boolean that indicates whether or not the browser should still handle this exception.
    return false
  }

  private setupViewMenu(): MenuItem[] {
    const viewMenuItems: GenericMenuItem[] = [
      {
        key: "Backspace",
        shortcut: "Backspace",
        name: "Undo last point",
        action: () => this.undo()
      },
      {
        key: "Escape",
        shortcut: "Esc",
        name: "Reset action",
        action: () => this.clearAllToolDataExceptDistanceCalculator()
      },
      {
        shortcut: "C",
        name: "Clear map",
        action: () => this.clearMap()
      },
      {
        key: "",
        shortcut: "+",
        name: "Zoom in (use Shift+ for more)",
        action: () => this.map.zoomIn()
      },
      {
        key: "",
        shortcut: "-",
        name: "Zoom out (use Shift- for more)",
        action: () => this.map.zoomOut()
      }
    ]

    const toggleMenuItems: GenericMenuItem[] = [
      {
        shortcut: "Z",
        name: "Auto-zoom to extents of data",
        id: "auto-zoom",
        action: () => this.toggleAutoZoom()
      },
      {
        shortcut: "T",
        name: "Show MapVis tile grid",
        id: "tile-grid",
        action: () => this.toggleShowTileGrid()
      },
      {
        name: "Show urban sections in routes",
        id: "urban-sections",
        action: () => this.toggleOptionUrbanSections()
      }
    ]

    const windowMenuItems: GenericMenuItem[] = [
      {
        key: "KeyW",
        shortcut: "W",
        name: "Show inspector window...",
        id: this.htmlIdInspectorWindow,
        action: () => this.inspectorWindow.setVisible(!this.inspectorWindow.isVisible())
      },
      {
        key: "KeyL",
        shortcut: "L",
        name: "Show logging window...",
        id: this.htmlIdLogWindow,
        action: () => this.logWindow.setVisible(!this.logWindow.isVisible())
      }
    ]
    return [viewMenuItems, separatorMenuItem, toggleMenuItems, separatorMenuItem, windowMenuItems].flat()
  }

  private setupLayersMenu(): MenuItem[] {
    // Note: the IDs of these menu items must be the keys of LayerType.
    const layersMenuItems: LayerMenuItem[] = [
      {
        id: "MapVisBasicMap",
        name: "MapVis: basic map",
        shortcut: "B",
        action: () => this.toggleLayer(LayerType.MapVisBasicMap)
      },
      {
        id: "MapVisIncidents",
        name: "MapVis: incidents",
        shortcut: "I",
        action: () => this.toggleLayer(LayerType.MapVisIncidents)
      },
      {
        id: "MapVisFlow",
        name: "MapVis: flow",
        shortcut: "F",
        action: () => this.toggleLayer(LayerType.MapVisFlow)
      },
      {
        id: "MapVisHillshade",
        name: "MapVis: hillshade",
        shortcut: "E",
        action: () => this.toggleLayer(LayerType.MapVisHillshade)
      },
      {
        id: "MapVisSatellite",
        name: "MapVis: satellite",
        shortcut: "A",
        action: () => this.toggleLayer(LayerType.MapVisSatellite)
      },
      {
        id: "MapVis3D",
        name: "MapVis: 3D",
        shortcut: "D",
        action: () => this.toggleLayer(LayerType.MapVis3D)
      },
      {
        id: "NdsLive",
        name: "NDS.Live",
        shortcut: "N",
        action: () => this.toggleLayer(LayerType.NdsLive)
      },
      {
        id: "NavTiles",
        name: "NK2 NavTiles",
        shortcut: "K",
        action: () => this.toggleLayer(LayerType.NavTiles)
      },
      {
        id: "RoutingApi",
        name: "Routing API",
        shortcut: "S",
        action: () => this.toggleLayer(LayerType.RoutingApi)
      },
      {
        id: "CoordinatePairs",
        name: "Coordinate pairs",
        shortcut: "O",
        action: () => this.toggleLayer(LayerType.CoordinatePairs)
      },
      {
        id: "TTPLocation",
        name: "TTP: location",
        shortcut: "P",
        action: () => this.toggleLayer(LayerType.TTPLocation)
      },
      {
        id: "TTP1s",
        name: "TTP: 1s data",
        shortcut: "Q",
        action: () => this.toggleLayer(LayerType.TTP1s)
      },
      {
        id: "TTP100ms",
        name: "TTP: 100ms data",
        shortcut: "R",
        action: () => this.toggleLayer(LayerType.TTP100ms)
      },
      {
        id: "GPX",
        name: "GPX",
        shortcut: "G",
        action: () => this.toggleLayer(LayerType.GPX)
      },
      {
        id: "JSON",
        name: "JSON/GeoJSON",
        shortcut: "J",
        action: () => this.toggleLayer(LayerType.JSON)
      }
    ]
    for (const layerType in LayerType) {
      if (!layersMenuItems.find((item) => item.id === layerType)) {
        throw new Error(`Layer type ${layerType} not found in layersMenuDefinition`)
      }
    }
    layersMenuItems.forEach((menuItem) => {
      ;[`${menuItem.id}-color`, `${menuItem.id}-text`, `${menuItem.id}-status`].forEach((id) => {
        document.getElementById(id)?.addEventListener("click", () => {
          menuItem.action?.()
        })
      })
    })
    return layersMenuItems
  }

  private setupOthersMenu(): MenuItem[] {
    const othersMenuItems: MenuItem[] = [
      {
        name: "What's new...",
        id: this.htmlIdWhatsNewWindow,
        action: () => this.whatsNewWindow.setVisible(!this.whatsNewWindow.isVisible())
      },
      {
        key: "KeyH",
        shortcut: "H",
        name: "Show help...",
        id: this.htmlIdHelpWindow,
        action: () => this.toggleHelpWindow()
      },
      separatorMenuItem,
      {
        name: "Enter default HTTP overhead size...",
        action: () => this.enterDefaultHttpOverheadSize()
      },
      {
        name: "Clear local storage (incl. API key)...",
        action: () =>
          this.yesNoDialog(
            "<strong>Clear local storage</strong><br/><br/>This will reload the page, ask for a new API key and reset the default overhead used for calculating the size of HTTP calls...<br/><br/>Are you sure?",
            this.clearLocalStorage,
            () => {}
          )
      },
      {
        name: "Skip '#' comment lines while importing",
        id: "skip-comments",
        action: () => this.toggleOptionSkipComments()
      },
      {
        name: "Convert routing API response from JSON to GPX...",
        action: () => this.createGpxFromRoutingResponseFile()
      },
      {
        name: "Create routes from JSON file...",
        action: () => this.createRoutesFromJsonFile()
      }
    ]
    return othersMenuItems
  }
}

export default App
