/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isHttpCodeNotAuthorized, isHttpCodeOk} from "./httpCodes"

/**
 * Read a JSON file from the server and parse it. This is asynchronous. Uses callbacks for success and failure.
 * @param fileUrl URL of file to download.
 * @param onSuccess Called after successfully reading the file.
 * @param onFailureNotAuthorized Called if the server returns a (401/403) Unauthorized status.
 * @param onFailureOther Called if the server returns any other status.
 */
export function requestJsonFile(
  fileUrl: string,
  onSuccess: (topLevelObject: any) => void,
  onFailureNotAuthorized: () => void,
  onFailureOther: (httpStatus: number) => void
): void {
  const rawFile = new XMLHttpRequest()
  rawFile.overrideMimeType("application/json")
  rawFile.onreadystatechange = () => {
    if (rawFile.readyState === 4) {
      if (isHttpCodeOk(rawFile.status)) {
        try {
          onSuccess(JSON.parse(rawFile.responseText))
        } catch (e) {
          console.warn(`Failed to parse JSON file: ${fileUrl}, error: ${e}`)
        }
      } else {
        console.warn(
          `Failed to load JSON file: ${fileUrl}, status: ${rawFile.status}, readyState: ${rawFile.readyState}`
        )
        if (isHttpCodeNotAuthorized(rawFile.status)) {
          onFailureNotAuthorized()
        } else {
          onFailureOther(rawFile.status)
        }
      }
    }
  }
  // Send the request for the file.
  rawFile.open("GET", fileUrl, false)
  rawFile.send(null)
}

/**
 * Triggers the download of a file to local machine in the browser. These are typically stored in a "Downloads"
 * folder.
 * @param downloadAsFileName Filename to save the file as.
 * @param contents Contents of the file.
 */
export function triggerDownloadFileInBrowser(downloadAsFileName: string, contents: string): void {
  const fileContents = new Blob([contents], {type: "text/plain"})
  const a = document.createElement("a")
  a.href = URL.createObjectURL(fileContents)
  a.download = downloadAsFileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Create a filename based on the current time. The filename will be created using local time, not UTC.
 * @param filePrefix Fixed prefix for filename, will be followed by "_".
 * @param fileExt Fixed postfix for filename, will be preceded by ".".
 */
export function createTimeBasedFilename(filePrefix: string, fileExt: string): string {
  const padToTwoDigits = (num: number) => num.toString().padStart(2, "0")
  const now = new Date()
  return (
    `${filePrefix}_${now.getFullYear()}${padToTwoDigits(now.getMonth() + 1)}${padToTwoDigits(now.getDate())}_` +
    `${padToTwoDigits(now.getHours())}${padToTwoDigits(now.getMinutes())}${padToTwoDigits(now.getSeconds())}` +
    `.${fileExt}`
  )
}
