/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {LayerLineParserRegex, LayerType} from "./parserTypes"
import {Feature, GeoJsonProperties} from "geojson"
import {LngLat} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {isHttpCodeOk} from "../common/httpCodes"
import LogWindow from "../app/logWindow"
import GeoHash from "../common/geoHash"

export class ParserRoutingApi extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parseLine(lineNumber: number, line: string, def: LayerLineParserRegex): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const regex = def.regex
      .replace("{x1}", "([0-9.]+)")
      .replace("{y1}", "([0-9.]+)")
      .replace("{x2}", "([0-9.]+)")
      .replace("{y2}", "([0-9.]+)")
    let from = 0
    while (from < line.length) {
      const match = RegExp(new RegExp(regex)).exec(line.slice(from))
      if (!match) {
        break
      }
      const indexes = {
        x1: def.regex.indexOf("{x1}"),
        y1: def.regex.indexOf("{y1}"),
        x2: def.regex.indexOf("{x2}"),
        y2: def.regex.indexOf("{y2}")
      }
      const origin: LngLat = {lng: 0, lat: 0}
      const destination: LngLat = {lng: 0, lat: 0}
      const sortedKeys = Object.keys(indexes).sort(
        (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
      )
      sortedKeys.forEach((key, i) => {
        const value = parseFloat(match[i + 1])
        switch (key) {
          case "x1":
            origin.lng = value
            break
          case "y1":
            origin.lat = value
            break
          case "x2":
            destination.lng = value
            break
          case "y2":
            destination.lat = value
            break
        }
      })
      const metadata = {
        lineNumber: lineNumber,
        line: line,
        ...(httpStatusCode && {httpStatusCode: httpStatusCode})
      }
      const polygon = this.createFeatureFromRequest(
        origin,
        destination,
        def.color,
        metadata,
        def.layerType,
        time,
        sizeInBytes
      )
      if (!polygon) {
        return features
      }
      features.push(polygon)
      from = from + match.index + match[0].length
    }
    return features
  }

  private createFeatureFromRequest(
    origin: LngLat,
    destination: LngLat,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature | undefined {
    if (metadata.httpStatusCode && !isHttpCodeOk(metadata.httpStatusCode)) {
      color = this.modifyColorToErrorState(color)
    }
    const geoHash = `${new GeoHash(origin).getHash()}:${new GeoHash(destination).getHash()}`
    const extendedMetadata = {
      ...metadata,
      ...(time && {time: time.getTime()}),
      ...{size: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: layerType,
      origin: origin,
      destination: destination,
      geoHash: geoHash
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [origin.lat, origin.lng]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: layerType,
        geoHash: geoHash,
        "circle-radius": 10,
        "circle-color": "rgba(255,100,168,0.7)",
        text: "(R)",
        "text-color": "rgb(255,204,0)"
      }
    }
  }
}

export default ParserRoutingApi
