/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {LayerLineParserRegex} from "./parserTypes"
import {PointXY} from "../common/geo"
import {Feature} from "geojson"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {isValidLat, isValidLng} from "../common/wgs84"
import LogWindow from "../app/logWindow"

export class ParserCoordinatePairs extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parseLine(lineNumber: number, line: string, def: LayerLineParserRegex): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const regex = def.regex.replace("{x}", "([+-]?[0-9.]+)").replace("{y}", "([+-]?[0-9.]+)")
    let from = 0
    while (from < line.length) {
      const match = RegExp(new RegExp(regex)).exec(line.slice(from))
      if (!match?.index || /\d/.test(match[0].slice(from, match.index))) {
        break
      }
      const indexes = {
        x: def.regex.indexOf("{x}"),
        y: def.regex.indexOf("{y}")
      }
      const coordinate: PointXY = {x: 0, y: 0}
      const sortedKeys = Object.keys(indexes).sort(
        (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
      )
      sortedKeys.forEach((key, i) => {
        const value = parseFloat(match[i + 1])
        switch (key) {
          case "x":
            coordinate.x = value
            break
          case "y":
            coordinate.y = value
            break
        }
      })
      if (!isValidLng(coordinate.x) || !isValidLat(coordinate.y)) {
        break
      }
      const metadata = {
        lineNumber: lineNumber,
        line: line,
        ...(httpStatusCode && {httpStatusCode: httpStatusCode})
      }
      const point = this.createPointFromCoordinates(coordinate, def.color, metadata, def.layerType, time, sizeInBytes)
      features.push(point)
      from = from + match.index + match[0].length
    }
    return features
  }
}

export default ParserCoordinatePairs
