/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {LayerLineParserRegex, LayerType} from "./parserTypes"
import {TileXYZ} from "../common/geo"
import {Feature, GeoJsonProperties} from "geojson"
import {isValidLat, isValidLng, mercatorToLatitude, mercatorToLongitude} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {isHttpCodeOk} from "../common/httpCodes"
import LogWindow from "../app/logWindow"

export class ParserMapVis extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parseLine(lineNumber: number, line: string, def: LayerLineParserRegex): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const regex = def.regex.replace("{x}", "([0-9.]+)").replace("{y}", "([0-9.]+)").replace("{z}", "([0-9.]+)")
    let from = 0
    while (from < line.length) {
      const match = RegExp(new RegExp(regex)).exec(line.slice(from))
      if (!match) {
        break
      }
      const indexes = {
        x: def.regex.indexOf("{x}"),
        y: def.regex.indexOf("{y}"),
        z: def.regex.indexOf("{z}")
      }
      const tile: TileXYZ = {x: 0, y: 0, z: 0}
      const sortedKeys = Object.keys(indexes).sort(
        (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
      )
      sortedKeys.forEach((key, i) => {
        const value = parseInt(match[i + 1])
        switch (key) {
          case "x":
          case "y":
          case "z":
            tile[key] = value
            break
        }
      })
      const metadata = {
        lineNumber: lineNumber,
        line: line,
        ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
        tileX: tile.x,
        tileY: tile.y,
        tileLevel: tile.z
      }
      const polygon = this.createPolygonFromMapVisTile(tile, def.color, metadata, def.layerType, time, sizeInBytes)
      if (!polygon) {
        return features
      }
      features.push(polygon)
      from = from + match.index + match[0].length
    }
    return features
  }

  private createPolygonFromMapVisTile(
    tile: TileXYZ,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature | undefined {
    if (metadata.httpStatusCode && !isHttpCodeOk(metadata.httpStatusCode)) {
      color = this.modifyColorToErrorState(color)
    }
    const resolution = 1 << tile.z
    const leftLon = mercatorToLongitude(tile.x / resolution)
    const rightLon = mercatorToLongitude((tile.x + 1) / resolution)
    const topLat = mercatorToLatitude(tile.y / resolution)
    const bottomLat = mercatorToLatitude((tile.y + 1) / resolution)
    if (!isValidLat(topLat) || !isValidLat(bottomLat) || !isValidLng(leftLon) || !isValidLng(rightLon)) {
      this.logWindow.log(`Invalid lat/lon: (${leftLon}${bottomLat}), (${rightLon}, ${topLat})`)
      return undefined
    }
    const southWest = {lng: leftLon, lat: bottomLat}
    const northEast = {lng: rightLon, lat: topLat}
    return this.createRectangle(southWest, northEast, color, metadata, layerType, time, sizeInBytes)
  }
}

export default ParserMapVis
