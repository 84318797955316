/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Tools} from "./appTypes"

type ToolDefinition = {
  icon: string
  tool: keyof Tools
}

export class Toolbox {
  private readonly onSelectTool: (key: keyof Tools) => void
  private readonly icons: ToolDefinition[]

  constructor(onSelectTool: (id: keyof Tools) => void, onHelp: () => void) {
    this.onSelectTool = onSelectTool
    this.icons = [
      {icon: "tool-data-selector", tool: "dataSelector"},
      {icon: "tool-distance-calculator", tool: "distanceCalculator"},
      {icon: "tool-export-json", tool: "routeCreatorJson"},
      {icon: "tool-export-gpx", tool: "routeCreatorGpx"},
      {icon: "tool-tile-editor", tool: "tileEditor"}
    ]

    // Add click event listener to each icon.
    this.icons.forEach((icon) => {
      const icon_image = document.getElementById(icon.icon)! as HTMLImageElement
      icon_image.addEventListener("click", () => {
        this.clearSelectedIcons()
        icon_image.classList.add("selected")
        this.onSelectTool(icon.tool)
      })
    })
    const icon_image_help = document.getElementById("tool-help")! as HTMLImageElement
    icon_image_help.addEventListener("click", () => {
      onHelp()
    })
  }

  public selectInitialTool() {
    this.clearSelectedIcons()
    const selected_icon_image = document.getElementById("tool-data-selector")! as HTMLImageElement
    selected_icon_image.classList.add("selected")
    this.onSelectTool("dataSelector")
  }

  private clearSelectedIcons() {
    this.icons.forEach(({icon: icon}) => {
      const icon_image = document.getElementById(icon)! as HTMLImageElement
      icon_image.classList.remove("selected")
    })
  }
}

export default Toolbox
