/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {LayerLineParserRegex, LayerType} from "./parserTypes"
import {BoundingBox, TileXY} from "../common/geo"
import {Feature, GeoJsonProperties} from "geojson"
import {isValidLat, isValidLng} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {isHttpCodeOk} from "../common/httpCodes"
import LogWindow from "../app/logWindow"

export class ParserNavTiles extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parseLine(lineNumber: number, line: string, def: LayerLineParserRegex): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const regex = def.regex.replace("{x}", "([0-9.]+)").replace("{y}", "([0-9.]+)")
    let from = 0
    while (from < line.length) {
      const match = RegExp(new RegExp(regex)).exec(line.slice(from))
      if (!match) {
        break
      }
      const indexes = {
        x: def.regex.indexOf("{x}"),
        y: def.regex.indexOf("{y}")
      }
      const tile: TileXY = {x: 0, y: 0}
      const sortedKeys = Object.keys(indexes).sort(
        (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
      )
      sortedKeys.forEach((key, i) => {
        const value = parseInt(match[i + 1])
        switch (key) {
          case "x":
            tile[key] = value
            break
          case "y":
            tile[key] = value
            break
        }
      })
      const metadata = {
        lineNumber: lineNumber,
        line: line,
        ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
        tileX: tile.x,
        tileY: tile.y
      }
      const polygon = this.createPolygonFromNavTilesTile(tile, def.color, metadata, def.layerType, time, sizeInBytes)
      if (!polygon) {
        return features
      }
      features.push(polygon)
      from = from + match.index + match[0].length
    }
    return features
  }

  private createPolygonFromNavTilesTile(
    tile: TileXY,
    color: GeoJsonProperties,
    metadata: any,
    layerType: LayerType,
    time?: Date,
    sizeInBytes?: number
  ): Feature | undefined {
    if (metadata.httpStatusCode && !isHttpCodeOk(metadata.httpStatusCode)) {
      color = this.modifyColorToErrorState(color)
    }
    const tileLevel = 13
    const sizeX = 1 << (tileLevel + 1)
    const sizeY = 1 << tileLevel
    const degreesPerTileUnitX = 360.0 / sizeX
    const degreesPerTileUnitY = 180.0 / sizeY

    const leftLon = -180.0 + tile.x * degreesPerTileUnitX
    const rightLon = -180.0 + (tile.x + 1) * degreesPerTileUnitX
    const topLat = 90.0 - tile.y * degreesPerTileUnitY
    const bottomLat = 90.0 - (tile.y + 1) * degreesPerTileUnitY
    if (!isValidLat(topLat) || !isValidLat(bottomLat) || !isValidLng(leftLon) || !isValidLng(rightLon)) {
      this.logWindow.log(`Invalid lat/lon: (${leftLon}${bottomLat}), (${rightLon}, ${topLat})`)
      return undefined
    }
    const bounds = BoundingBox.empty().extendToLngLat({lng: leftLon, lat: topLat}).extendToLngLat({
      lng: rightLon,
      lat: bottomLat
    })
    const extendedMetadata = {
      ...metadata,
      ...(time && {time: time.getTime()}),
      ...{size: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [leftLon, topLat],
            [rightLon, topLat],
            [rightLon, bottomLat],
            [leftLon, bottomLat],
            [leftLon, topLat]
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: layerType,
        geoHash: bounds.toGeoHash(),
        "fill-opacity": color?.["fill-opacity"],
        "fill-color": color?.["fill-color"],
        "fill-outline-color": color?.["fill-outline-color"]
      }
    }
  }
}

export default ParserNavTiles
