/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {approximateSizeInBytesOfObject} from "./objects"

/**
 * A simple key/value store for metadata.
 */
export class MetadataStore {
  private metadataStore: {[key: string]: any}
  private metadataKey: number

  constructor() {
    this.metadataStore = {}
    this.metadataKey = 0
  }

  /**
   * Store metadata and return a key to retrieve it later.
   * @param metadata Any metadata.
   * @returns A key to retrieve the metadata later.
   */
  store(metadata: any): string {
    const key = this.generateNextKey()
    this.metadataStore[key] = metadata
    return key
  }

  /**
   * Update metadata by key.
   * @param key The key returned by store().
   * @param metadata The new metadata.
   */
  update(key: string, metadata: any): void {
    this.metadataStore[key] = metadata
  }

  /**
   * Retrieve metadata by key.
   * @param key The key returned by store().
   * @returns The metadata, or undefined if the key is not found.
   */
  retrieve(key?: string): any {
    return key ? this.metadataStore[key] : undefined
  }

  /**
   * Remove metadata by key.
   * @param key The key returned by store().
   */
  remove(key: string): void {
    delete this.metadataStore[key]
  }

  /**
   * Remove all metadata.
   */
  removeAll(): void {
    this.metadataStore = {}
    this.metadataKey = 0
  }

  /**
   * Get the total size of all metadata in bytes.
   * @returns The total size in bytes.
   */
  totalSizeInBytes(): number {
    return approximateSizeInBytesOfObject(this.metadataStore)
  }

  private generateNextKey() {
    return `#${++this.metadataKey}` // No worries, this effectively never overflows in a lifetime.
  }
}
