/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import Parser from "./parser"
import {calculateBoundingBox} from "../common/geojson"
import {addAllElements} from "../common/objects"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {LayerType} from "./parserTypes"
import LogWindow from "../app/logWindow"

export class ParserGeoJson extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parse(fileName: string, contents: string): Feature[] | undefined {
    this.logWindow.log(`Parse GeoJSON file: ${fileName}`)
    let features: Feature[] = []
    const json = JSON.parse(contents)
    if (!json.features) {
      return undefined
    }
    addAllElements(features, json.features)

    for (const feature of features) {
      const metadata = {
        layer: LayerType.JSON,
        bounds: calculateBoundingBox(feature)
      }
      const metadataKey = this.metadataStore.store(metadata)
      if (!feature.properties) {
        feature.properties = {}
      }
      feature.properties.metadata = metadataKey
    }
    return features
  }

  parseString(geoJson: string): Feature[] | undefined {
    try {
      const object = JSON.parse(geoJson)
      if (typeof object !== "object") {
        return undefined
      }
      const bounds = calculateBoundingBox(object)
      if (object.type === "LineString" || object.type === "Point" || object.type === "Polygon") {
        const metadata = {
          layer: LayerType.JSON,
          bounds: bounds
        }
        const metadataKey = this.metadataStore.store(metadata)
        const feature: Feature = {
          type: "Feature",
          geometry: object,
          properties: {
            metadata: metadataKey
          }
        }
        return [feature]
      } else if (object.type === "Feature") {
        return object
      } else if (object.type === "FeatureCollection") {
        return object.features
      }
    } catch (e) {}
    return undefined
  }
}

export default ParserGeoJson
