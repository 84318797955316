/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {addAllElements, approximateSizeInBytesOfObject, truncateString} from "../common/objects"
import {BoundingBox} from "../common/geo"
import {MetadataStore} from "../common/metadata"
import {formatDate} from "../common/datetime"

export const DEFAULT_FILE_ID = "_file"
export const DEFAULT_LAYER_ID = "_layer"
export const TIMESTAMP_ANYTIME = -1
const DEFAULT_FILE_NAME = "[ Dropped text items ]"

/**
 * The enum FilterMatch is used to filter on tile levels.
 */
export enum FilterMatch {
  All,
  LessThan,
  LessEqual,
  Equal,
  GreaterEqual,
  GreaterThan
}

/**
 * The type FilterState contains the values of filters.
 */
export type FilterStates = {
  level: number
  match: FilterMatch
}

/**
 * This stores the state for each data layer type.
 */
export type LayerState = {
  show: boolean
  count: number
  sizeInBytes: number
  supportsLevel: boolean // True if the layer supports tile levels.
}

export type LayerStates = Record<string, LayerState>

/**
 * This type describe a data file in the data store.
 */
export type DataFileState = {
  show: boolean
}

/**
 * This stores all data file states, per data file.
 */
export type DataFileStates = Record<string, DataFileState>

export type DataFileInfo = {
  id: string
  name: string
  sizeInBytes: number
}

/**
 * This type describes the features per layer type.
 */
type FeaturesPerLayer = Record<string, Feature[]>

/**
 * This type describes the content of a data file.
 */
type DataFileContent = Omit<DataFileInfo, "id"> & {
  featuresPerLayer: FeaturesPerLayer
  bounds: BoundingBox
}

/**
 * This type describes the content of all data files, per file.
 */
type DataFileContentPerFile = Record<string, DataFileContent>

export class DataStore {
  private readonly metadataStore: MetadataStore
  private dataFileContentPerFile: DataFileContentPerFile = {}

  constructor(metadataStore: MetadataStore) {
    this.metadataStore = metadataStore
    this.removeAll()
  }

  getDataFileInfoList(): DataFileInfo[] {
    return Object.keys(this.dataFileContentPerFile)
      .filter((dataFile) => {
        for (const featuresPerLayer in this.dataFileContentPerFile[dataFile].featuresPerLayer) {
          if (featuresPerLayer.length > 0) {
            return true
          }
        }
        return false
      })
      .map((id) => {
        const dataFile = this.dataFileContentPerFile[id]
        const sizeInBytes = approximateSizeInBytesOfObject(dataFile.featuresPerLayer)
        return {
          id: id,
          name: dataFile.name,
          sizeInBytes: sizeInBytes
        }
      })
  }

  addFeaturesFromDataFile(features: Feature[], sourceFile?: File): string {
    const fileId = this.getFileId(sourceFile)
    const fileName = this.getFileName(sourceFile)
    const featuresPerLayer: FeaturesPerLayer = {}
    let bounds = BoundingBox.empty()
    features.forEach((feature) => {
      const metadata = this.metadataStore.retrieve(feature.properties?.metadata)
      const layerType = metadata?.layer ? metadata.layer : DEFAULT_LAYER_ID
      if (!featuresPerLayer[layerType]) {
        featuresPerLayer[layerType] = []
      }
      featuresPerLayer[layerType].push(feature)
      bounds = bounds.extendWithOther(metadata?.bounds)
    })

    // Create a box around the file extents.
    if (!featuresPerLayer[DEFAULT_LAYER_ID]) {
      featuresPerLayer[DEFAULT_LAYER_ID] = []
    }
    featuresPerLayer[DEFAULT_LAYER_ID].push(this.createExtentsBox(bounds))

    this.dataFileContentPerFile[fileId] = {
      name: fileName,
      bounds: bounds,
      sizeInBytes: approximateSizeInBytesOfObject(features),
      featuresPerLayer: featuresPerLayer
    }
    return fileId
  }

  getBoundsOfDataFile(file?: File): BoundingBox {
    const fileId = this.getFileId(file)
    return this.dataFileContentPerFile[fileId].bounds
  }

  getBoundsOfDataFiles(dataFileStates: DataFileStates): BoundingBox {
    let bounds = BoundingBox.empty()
    for (const id in this.dataFileContentPerFile) {
      if (dataFileStates[id].show) {
        bounds = bounds.extendWithOther(this.dataFileContentPerFile[id].bounds)
      }
    }
    return bounds
  }

  calculateBoundsFromFeatures(features: Feature[]) {
    return features
      .map((feature) => {
        if (feature.properties?.metadata) {
          const metadata = this.metadataStore.retrieve(feature.properties.metadata)
          if (metadata?.bounds) {
            return metadata.bounds
          }
        }
        return undefined
      })
      .filter((bounds) => bounds !== undefined)
      .reduce((bounds, current) => bounds.extendWithOther(current), BoundingBox.empty())
  }

  getFeaturesFromDataFiles(
    layerStates: LayerStates,
    dataFileStates: DataFileStates,
    filterStates: FilterStates
  ): Feature[] {
    const features: Feature[] = []
    for (const dataFile in this.dataFileContentPerFile) {
      if (!dataFileStates[dataFile].show) {
        continue
      }
      const featuresPerLayer = this.dataFileContentPerFile[dataFile].featuresPerLayer
      for (const layerType in featuresPerLayer) {
        const layerState = layerStates[layerType]
        if (layerState && !layerState.show) {
          continue
        }
        if (!layerState?.supportsLevel || filterStates.match === FilterMatch.All) {
          // Super-fast block copy.
          addAllElements(features, featuresPerLayer[layerType])
        } else {
          // Need to copy feature by feature.
          for (const feature of featuresPerLayer[layerType]) {
            const metadata = this.metadataStore.retrieve(feature.properties?.metadata)
            if (metadata && metadata.tileLevel !== undefined) {
              switch (filterStates.match) {
                case FilterMatch.LessThan:
                  if (metadata.tileLevel >= filterStates.level) {
                    continue
                  }
                  break
                case FilterMatch.LessEqual:
                  if (metadata.tileLevel > filterStates.level) {
                    continue
                  }
                  break
                case FilterMatch.Equal:
                  if (metadata.tileLevel !== filterStates.level) {
                    continue
                  }
                  break
                case FilterMatch.GreaterEqual:
                  if (metadata.tileLevel < filterStates.level) {
                    continue
                  }
                  break
                case FilterMatch.GreaterThan:
                  if (metadata.tileLevel <= filterStates.level) {
                    continue
                  }
                  break
              }
            }
            features.push(feature)
          }
        }
      }
    }
    return features
  }

  exists(file: File): boolean {
    return this.dataFileContentPerFile[this.getFileId(file)] !== undefined
  }

  /**
   * Clears all features in the data store for a specific data file.
   */
  remove(dataFile: string = DEFAULT_FILE_ID) {
    // TODO: [tech-debt] This does not remove the metadata stored for the objects in this file.
    delete this.dataFileContentPerFile[dataFile]
  }

  /**
   * Initializes and clear the data store.
   */
  removeAll() {
    this.dataFileContentPerFile = {}
    this.metadataStore.removeAll()
  }

  /**
   * Returns the approximate size in bytes of the data store.
   */
  totalSizeInBytes(): number {
    return approximateSizeInBytesOfObject(this.dataFileContentPerFile)
  }

  private createExtentsBox(bounds: BoundingBox): Feature {
    return {
      type: "Feature",
      properties: {
        time: TIMESTAMP_ANYTIME,
        "line-width": 1,
        "line-color": "rgb(0,0,0)",
        "fill-color": "rgb(0,0,0)",
        "line-dasharray": [2, 2],
        generated: true
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [bounds.southWest.lng, bounds.southWest.lat],
          [bounds.southWest.lng, bounds.northEast.lat],
          [bounds.northEast.lng, bounds.northEast.lat],
          [bounds.northEast.lng, bounds.southWest.lat],
          [bounds.southWest.lng, bounds.southWest.lat]
        ]
      }
    }
  }

  private getFileId(file?: File) {
    return file ? this.createDataFileId(file) : DEFAULT_FILE_ID
  }

  private getFileName(file?: File) {
    return file ? this.createDataFileName(file) : DEFAULT_FILE_NAME
  }

  private createDataFileId(file: File): string {
    return `${file.name}_${file.lastModified}`
  }

  private createDataFileName(file: File): string {
    let name = file.name
    const fileId = this.createDataFileId(file)
    for (const id in this.dataFileContentPerFile) {
      if (this.dataFileContentPerFile[id].name === name && id !== fileId) {
        name = `${file.name} (${formatDate(new Date(file.lastModified))})`
        break
      }
    }
    return truncateString(name, 32)
  }
}

export default DataStore
