/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox} from "../common/geo"
import {Feature, LineString, Point} from "geojson"
import Parser from "./parser"
import {addAllElements} from "../common/objects"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {LayerType} from "./parserTypes"
import LogWindow from "../app/logWindow"

export class ParserGpx extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parse(fileName: string, contents: string): Feature[] {
    this.logWindow.log(`Parse GPX file: ${fileName}`)

    let features: Feature[] = []
    const parser = new DOMParser()
    const doc = parser.parseFromString(contents, "text/xml")

    for (const rte of Array.from(doc.getElementsByTagName("gpx")[0].getElementsByTagName("rte"))) {
      const rteFeatures: Feature<LineString>[] = [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [] as [number, number][]
          },
          properties: {
            "line-width": 2,
            "line-stroke-width": 0,
            "line-dasharray": [1, 1],
            "line-color": "rgb(214,46,95,0.5)"
          }
        }
      ]

      for (const rtept of Array.from(rte.getElementsByTagName("rtept"))) {
        const lon = parseFloat(rtept.getAttribute("lon")!)
        const lat = parseFloat(rtept.getAttribute("lat")!)
        rteFeatures[0].geometry.coordinates.push([lon, lat])

        const metadata = {
          layer: LayerType.GPX,
          bounds: BoundingBox.fromLngLat({lng: lon, lat: lat})
        }
        rteFeatures[0].properties!.metadata = this.metadataStore.store(metadata)
      }
      addAllElements(features, rteFeatures)
    }

    for (const trk of Array.from(doc.getElementsByTagName("gpx")[0].getElementsByTagName("trk"))) {
      for (const trkseg of Array.from(trk.getElementsByTagName("trkseg"))) {
        const trkFeatures: Feature<Point>[] = []
        for (const trkpt of Array.from(trkseg.getElementsByTagName("trkpt"))) {
          const lon = parseFloat(trkpt.getAttribute("lon")!)
          const lat = parseFloat(trkpt.getAttribute("lat")!)
          const metadata = {
            layer: LayerType.GPX,
            bounds: BoundingBox.fromLngLat({lng: lon, lat: lat})
          }
          const metadataKey = this.metadataStore.store(metadata)
          const feature: Feature<Point> = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [lon, lat]
            },
            properties: {
              metadata: metadataKey,
              "circle-radius": 2,
              "circle-color": "rgb(139,188,41)"
            }
          }
          trkFeatures.push(feature)
        }
        addAllElements(features, trkFeatures)
      }
    }
    return features
  }
}

export default ParserGpx
