/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, GeoJsonProperties, Position} from "geojson"
import Parser from "./parser"
import {distanceInMeters} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {Settings} from "../app/settings"
import {BoundingBox} from "../common/geo"
import {LayerType} from "./parserTypes"
import LogWindow from "../app/logWindow"

export class ParserJson extends Parser {
  constructor(logWindow: LogWindow, settings: Settings, metadataStore: MetadataStore) {
    super(logWindow, settings, metadataStore)
  }

  parse(fileName: string, contents: string): Feature[] | undefined {
    this.logWindow.log(`Parse JSON file: ${fileName}`)

    let features: Feature[] = []
    const json = JSON.parse(contents)
    if (!("routes" in json) || !Array.isArray(json.routes)) {
      this.logWindow.log(`No 'routes' found in JSON file`)
      return undefined
    }
    for (const route of json.routes) {
      if (!("legs" in route) || !Array.isArray(route.legs)) {
        this.logWindow.log(`No 'legs' found in 'routes' item in JSON file`)
        return undefined
      }
      let points: Position[] = []
      if (this.settings.optionUrbanSections) {
        if ("sections" in route) {
          let routeLength = 0
          let routeUrbanLength = 0
          for (const leg of route.legs) {
            if (!("points" in leg)) {
              this.logWindow.log(`No 'points' found in 'legs' item in JSON file`)
              return undefined
            }
            for (const point of leg.points) {
              if (!("longitude" in point) || !("latitude" in point)) {
                this.logWindow.log(`No 'points' found in 'leg.poins' item in JSON file`)
                return undefined
              }

              points.push([point.longitude, point.latitude])
              if (points.length > 1) {
                routeLength += distanceInMeters(
                  {lng: points[points.length - 2][0], lat: points[points.length - 2][1]},
                  {lng: points[points.length - 1][0], lat: points[points.length - 1][1]}
                )
              }
            }
          }

          let start = 0
          for (const section of route.sections) {
            if ("sectionType" in section && section.sectionType.toUpperCase() === "URBAN") {
              if (section.startPointIndex > start) {
                const coordinates = points.slice(start, section.startPointIndex + 1)
                const metadata = {
                  layer: LayerType.JSON,
                  bounds: this.boundsFromPoints(coordinates)
                }
                const metadataKey = this.metadataStore.store(metadata)
                features.push({
                  type: "Feature",
                  properties: {
                    metadata: metadataKey,
                    "line-width": 4,
                    "line-offset": 0,
                    "line-color": "rgb(75,128,227)",
                    "line-outline": true
                  },
                  geometry: {
                    type: "LineString",
                    coordinates: coordinates
                  }
                })
              }
              const urbanPoints = points.slice(section.startPointIndex, section.endPointIndex + 1)
              for (let i = 1; i < urbanPoints.length; ++i) {
                routeUrbanLength += distanceInMeters(
                  {lng: urbanPoints[i - 1][0], lat: urbanPoints[i - 1][1]},
                  {lng: urbanPoints[i][0], lat: urbanPoints[i][1]}
                )
              }
              const metadata = {
                layer: LayerType.JSON,
                bounds: this.boundsFromPoints(urbanPoints)
              }
              const metadataKey = this.metadataStore.store(metadata)
              features.push({
                type: "Feature",
                properties: {
                  metadata: metadataKey,
                  urban: true,
                  "line-width": 4,
                  "line-offset": 0,
                  "line-color": "rgb(202,21,21)",
                  "line-outline": true
                },
                geometry: {
                  type: "LineString",
                  coordinates: urbanPoints
                }
              })
              start = section.endPointIndex - 1
            }
          }
          if (start < points.length) {
            const coordinates = points.slice(start)
            const metadata = {
              layer: LayerType.JSON,
              bounds: this.boundsFromPoints(coordinates)
            }
            const metadataKey = this.metadataStore.store(metadata)
            features.push({
              type: "Feature",
              properties: {
                metadata: metadataKey,
                "line-width": 4,
                "line-offset": 0,
                "line-color": "rgb(75,128,227)",
                "line-outline": true
              },
              geometry: {
                type: "LineString",
                coordinates: coordinates
              }
            })
          }
          this.logWindow.log(
            `Route with urban sections, total route length=${Math.round(routeLength)}m of which ${Math.round(
              routeUrbanLength
            )}m in urban areas`
          )
        } else {
          for (let legIndex = 0; legIndex < route.legs.length; ++legIndex) {
            const leg = route.legs[legIndex]
            if (!("points" in leg) || !Array.isArray(leg.points)) {
              this.logWindow.log(`No 'points' found in 'legs' item in JSON file`)
              return undefined
            }
            for (const point of leg.points) {
              if (!("longitude" in point) || !("latitude" in point)) {
                this.logWindow.log(`No 'points' found in 'legs' item in JSON file`)
                return undefined
              }
              points.push([point.longitude, point.latitude])
            }
            const properties: GeoJsonProperties = {
              "line-width": 4,
              "line-offset": 0,
              "line-color": "rgb(75,120,203)",
              "line-outline": true
            }
            if (route.legs.length > 0) {
              if ("summary" in leg) {
                properties["leg-index"] = legIndex
                for (let [name, value] of Object.entries(leg.summary)) {
                  if (typeof value === "string" || typeof value === "number") {
                    properties["leg-" + name] = value
                  }
                }
                let speed = (leg.summary.lengthInMeters / leg.summary.travelTimeInSeconds) * 3.6
                properties["speed"] = speed
                properties["line-color"] = this.colorFromSpeed(speed)
              }
            }
            if ("summary" in route) {
              for (let [name, value] of Object.entries(route.summary)) {
                if (typeof value === "string" || typeof value === "number") {
                  properties["route-" + name] = value
                }
              }
            }
            if (points.length !== 0) {
              const metadata = {
                layer: LayerType.JSON,
                bounds: this.boundsFromPoints(points)
              }
              const metadataKey = this.metadataStore.store(metadata)

              features.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: points
                },
                properties: {
                  ...properties,
                  metadata: metadataKey
                }
              })
            }
          }
        }
      } else {
        for (let legIndex = 0; legIndex < route.legs.length; ++legIndex) {
          let leg = route.legs[legIndex]
          if (!("points" in leg) || !Array.isArray(leg.points)) {
            this.logWindow.log(`No 'points' found in 'legs' item in JSON file`)
            return undefined
          }
          for (const point of leg.points) {
            if (!("longitude" in point) || !("latitude" in point)) {
              this.logWindow.log(`No 'latitude/longitude' found in 'leg.points' item in JSON file`)
              return undefined
            }
            points.push([point.longitude, point.latitude])
          }

          let properties: GeoJsonProperties = {
            "line-width": 4,
            "line-offset": 0,
            "line-color": "rgb(75,120,203)",
            "line-outline": true
          }
          if (route.legs.length > 0) {
            if ("summary" in leg) {
              properties["leg-index"] = legIndex
              for (let [name, value] of Object.entries(leg.summary)) {
                if (typeof value === "string" || typeof value === "number") {
                  properties["leg-" + name] = value
                }
              }
              const speed = (leg.summary.lengthInMeters / leg.summary.travelTimeInSeconds) * 3.6
              properties["line-color"] = this.colorFromSpeed(speed)
            }
          }
          if ("summary" in route) {
            let summary = route.summary
            for (let [name, value] of Object.entries(summary)) {
              if (typeof value === "string" || typeof value === "number") {
                properties["route-" + name] = value
              }
            }
          }

          if (points.length > 0) {
            const metadata = {
              layer: LayerType.JSON,
              bounds: this.boundsFromPoints(points)
            }
            const metadataKey = this.metadataStore.store(metadata)
            features.push({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: points
              },
              properties: {
                ...properties,
                metadata: metadataKey
              }
            })
          }
        }
      }
    }
    return features
  }

  private boundsFromPoints(positions: Position[]) {
    let bounds = BoundingBox.empty()
    for (const position of positions) {
      bounds = bounds.extendToLngLat({lng: position[0], lat: position[1]})
    }
    return bounds
  }

  private colorFromSpeed(speed: number) {
    const speedColors = [
      {speed: 30, color: "rgb(41,200,236)"},
      {speed: 50, color: "rgb(74,204,24)"},
      {speed: 70, color: "rgb(251,172,20)"},
      {speed: 90, color: "rgb(241,49,37)"},
      {speed: 110, color: "rgb(148,26,27)"},
      {speed: 130, color: "rgb(179,14,236)"}
    ]
    let color = speedColors[0].color
    for (const entry of speedColors) {
      if (speed >= entry.speed) {
        color = entry.color
        break
      }
    }
    return color
  }
}

export default ParserJson
