/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {APPLICATION_VERSION} from "../common/version"
import {marked} from "marked"
import MenuBar from "./menuBar"
import HtmlWindow from "./htmlWindow"

export class WhatsNewWindow extends HtmlWindow {
  private readonly MARKDOWN_WHATS_NEW_CONTENT = `# What's new in version ${APPLICATION_VERSION}?

### 2024-10-11

* Improved visual tile level selector. You can now just select the tile levels and criteria on the screen.
* Added the ability show routing API requests on the map.

### 2024-10-10

* Escape key no longer removes existing measured distances; it just starts a new measurement.

### 2024-10-07

* Significant speed-up for TTP files, showing vehicle GNSS positions by default only.

### 2024-10-04

* Removed "Tools" menu and made an icon selector in a toolbar.

### 2024-10-02

* TTP support for lane level prediction (message type 140) is added.

### 2024-09-29

* GPX export now produces GPX files that are correctly read by Lockito (used to have speed issues).

### 2024-09-19

* The data sizes of layers are now shown in the layers list.
* During import, a window is shown that loading is in progress.
* The HTTP overhead is now by default set to 1024.   

### 2024-09-18

* Duplicates are filtered better and the timeline shows how many Kbs were found in duplicate tiles. 

### 2024-09-16

* Tiles in volatile layers (traffic incidents and flow) only have red borders when retrieved within 90 seconds. 

### 2024-09-10

* Minor bug fixes.

### 2024-08-21

* Added HTTP overhead of (default) 2Kb per HTTP request to tile sizes to account for HTTP headers. You can modify the default in the 
'Others' menu.

### 2024-08-20

* Calculated sizes for time ranges now show how many items were incomplete and did not include a size in bytes.    
* Tile boundaries are now colored red and get thicker if the items was found more than once.
* Automatic "What's new" dialog on first start of new version.

**Note:** Press "**H**" for more help on using the Map Data Visualizer...`

  constructor(htmlElementId: string) {
    super(htmlElementId)
    const content = document.getElementById(`${htmlElementId}-content`)
    if (!content) {
      throw new Error(`WhatsNewWindow: could not find element with id '${htmlElementId}-content'`)
    }
    content.innerHTML = marked(this.MARKDOWN_WHATS_NEW_CONTENT) as string
    MenuBar.updateMenuItemToggle(`${htmlElementId}-menuitem`, this.isVisible())
  }
}

export default WhatsNewWindow
