/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat} from "../common/wgs84"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Feature} from "geojson"
import {Tool} from "./tool"
import {MapGeoJSONFeature, Point as MapLibrePoint} from "maplibre-gl"
import {MetadataStore} from "../common/metadata"
import MapView from "../app/mapView"
import InspectorWindow from "../app/inspectorWindow"
import {LogWindow} from "../app/logWindow"

/**
 * This tool allows the user to select map data on the map and get metadata info in the
 * metadata window.
 */
export class DataSelectorTool extends Tool {
  static readonly ID = "select"
  private readonly queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[]
  private selectedFeatures: Feature[] = []
  private selectedIndex = 0

  constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[]
  ) {
    super(
      map,
      inspectorWindow,
      logWindow,
      metadataStore,
      DataSelectorTool.ID,
      "Select data on map",
      `
Click on the map to select any data on 
the map. An box with metadata will pop up.
You can scroll the metadata window up/down.
Use 'W' to close the metadata window.
`
    )
    this.queryFeatures = queryFeatures
  }

  onClick(location: LngLat, point: MapLibrePoint) {
    const features = this.queryFeatures(point)
    if (!features || features.length === 0) {
      this.selectedIndex = 0
      this.selectedFeatures = []
      this.refresh()
      return
    }

    // Check if we're clicking on the same feature.
    const f1 = JSON.stringify(features)
    const f2 = JSON.stringify(this.selectedFeatures)
    if (f1 === f2) {
      this.selectedIndex = (this.selectedIndex + 1) % features.length
    } else {
      this.selectedIndex = 0
      this.selectedFeatures = [...features]
    }
    this.refresh()
  }

  clearToolData() {
    this.selectedFeatures = []
    this.selectedIndex = 0
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "circle",
        paint: {
          "circle-radius": 5,
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(255,0,0)"]
        },
        filter: ["==", ["geometry-type"], "Point"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(0,156,143)"],
          "line-width": 6,
          "line-dasharray": [1, 1]
        },
        filter: ["any", ["==", ["geometry-type"], "Polygon"], ["==", ["geometry-type"], "LineString"]]
      },
      {
        type: "fill",
        paint: {
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,0,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.2]
        },
        filter: ["==", ["geometry-type"], "Polygon"]
      }
    ]
  }

  refresh() {
    if (this.selectedFeatures.length === 0) {
      this.draw([])
      return
    }

    if (this.selectedIndex >= this.selectedFeatures.length) {
      throw new Error(`Invalid selected index (${this.selectedIndex} for ${this.selectedFeatures.length} features)`)
    }

    const feature = this.selectedFeatures[this.selectedIndex]
    const metadata = this.metadataStore.retrieve(feature?.properties?.metadata)
    this.inspectorWindow.show(
      `Selected ${this.selectedIndex + 1} of ${this.selectedFeatures.length} features`,
      feature,
      metadata
    )
    this.draw([feature])
  }
}

export default DataSelectorTool
