/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import maplibregl, {
  GeoJSONSourceSpecification,
  MapGeoJSONFeature,
  MapLibreEvent,
  MapMouseEvent,
  Point as MapLibrePoint,
  RasterSourceSpecification,
  VectorSourceSpecification
} from "maplibre-gl"
import MapDataOverlay from "./mapDataOverlay"
import {BoundingBox} from "../common/geo"
import {requestJsonFile} from "../common/files"
import {LayerSpecification, SourceSpecification} from "@maplibre/maplibre-gl-style-spec"
import {FeatureCollection} from "geojson"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {MapDefinition, MapLayerGroupElement, MapSourceElement} from "./mapTypes"
import DataSelectorTool from "../tools/dataSelectorTool"
import DistanceCalculatorTool from "../tools/distanceCalculatorTool"
import InvalidApiKey from "../exceptions/invalidApiKey"
import {LngLat} from "../common/wgs84"
import {GenericMenuItem} from "./menuTypes"
import LogWindow from "./logWindow"

export class MapView {
  private readonly logWindow: LogWindow
  private readonly apiKey: string
  private readonly renderer: maplibregl.Map
  private readonly styleVersion = "24.1.*"
  private readonly zoomLevelMin = 0
  private readonly zoomLevelMax = 23
  private readonly mapNamePrefix = "map_"
  private readonly skipOverlaysInQueries: Record<string, boolean> = {}
  private readonly referenceLayerMap = "ref_map"
  private readonly referenceLayerLayers = "ref_layers"
  private readonly referenceLayerTools = "ref_tools"

  private mapDefinitionForMap: {[id: string]: MapDefinition} = {}
  private currentMapName: string | undefined
  private currentLayerIds: string[] = []
  private featureCollectionPerTool: Record<string, FeatureCollection> = {}
  private saved: {
    sources: MapSourceElement[]
    layerGroups: MapLayerGroupElement[]
  } = {
    sources: [],
    layerGroups: []
  }
  private nextLayerId = 0
  private mapLoaded = false

  constructor(
    containerId: string,
    logWindow: LogWindow,
    apiKey: string,
    onClick: (location: LngLat, point: MapLibrePoint) => void
  ) {
    this.logWindow = logWindow
    this.apiKey = apiKey
    this.renderer = new maplibregl.Map({
      style: {
        name: "ttom-tiles",
        glyphs: `https://api.tomtom.com/maps/orbis/assets/fonts/0.2.*/{fontstack}/{range}.pbf?key=${this.apiKey}&apiVersion=1`,
        sources: {},
        layers: [],
        metadata: {},
        sprite: `https://api.tomtom.com/maps/orbis/assets/sprites/0.2.*/sprite?key=${this.apiKey}&apiVersion=1&sourcesVersion=1&map=basic_street-light&trafficFlow=flow_relative-light&trafficIncidents=incidents_light`,
        version: 8
      },
      center: [4.87969, 52.35578], // Amsterdam.
      zoom: 8.5,
      container: containerId,
      antialias: true
    })

    // Make sure the map is initalized after loading/
    this.renderer.on("load", (mapLibreEvent: MapLibreEvent) => {
      this.onLoad(mapLibreEvent)
    })

    // Load the maps.
    const mapsFilename = "./maps.json"
    requestJsonFile(
      mapsFilename,
      (json) => {
        // Add all maps.
        Object.entries(json).forEach(([key, value]) => this.addMap(key, value))
        this.selectMap(this.defaultMap())
      },
      () => {
        this.logWindow.log(`Cannot load maps file (not authorized): ${mapsFilename}`)
      },
      (httpStatus) => this.logWindow.log(`Cannot load maps file: ${mapsFilename}, HTTP status: ${httpStatus}`)
    )

    // Set up the mouse click handler.
    this.setOnClickListener((mapMouseEvent: MapMouseEvent) => onClick(mapMouseEvent.lngLat, mapMouseEvent.point))
  }

  /**
   * Lambda function to return features on a clicked map point (if any).
   * The following features should be filtered:
   * - generated features, such as the extent box
   * - features created in non data-canvas overlays
   * - features with the same metadata (these can occur as MapLibre partitions the data and sometimes splits features)
   *
   * Important: this must be a lambda, not a function, to avoid binding issues.
   * @param point Point on screen map.
   */
  queryFeatures = (point: MapLibrePoint): MapGeoJSONFeature[] => {
    return this.renderer
      .queryRenderedFeatures(point)
      .filter((feature) => !feature.properties?.generated && !this.skipOverlaysInQueries[feature.source])
      .filter(
        (feature, index, self) =>
          !feature.properties?.metadata ||
          index === self.findIndex((other) => other.properties?.metadata === feature.properties?.metadata)
      )
  }

  /**
   * Lambda function to return the URL of a tile (if any).
   * Important: this must be a lambda, not a function, to avoid binding issues.
   * @param point Point on screen map.
   * @returns URL of the tile.
   */
  queryTileUrl = (point: MapLibrePoint): URL | undefined => {
    const features = this.renderer.queryRenderedFeatures(point)
    for (const feature of features) {
      const source = this.renderer.getSource(feature.source)
      if (
        feature._vectorTileFeature &&
        source &&
        source.type === "vector" &&
        "tiles" in source &&
        Array.isArray(source.tiles)
      ) {
        const pos = features[0]._vectorTileFeature
        if ("_z" in pos && "_x" in pos && "_y" in pos) {
          return new URL(source.tiles[0].replace("{z}", pos._z).replace("{x}", pos._x).replace("{y}", pos._y))
        }
      }
    }
    return undefined
  }

  showTileGrid = () => this.renderer.showTileBoundaries

  toggleShowTileGrid() {
    this.renderer.showTileBoundaries = !this.renderer.showTileBoundaries
    this.logWindow.log(`Show tile grid in map --> ${this.renderer.showTileBoundaries ? "on" : "off"}`)
  }

  getMapsMenu(): GenericMenuItem[] {
    return Object.entries(this.mapDefinitionForMap).map(([key]) => {
      return {
        id: key,
        name: this.mapDefinitionForMap[key].name,
        action: () => this.selectMap(key)
      }
    })
  }

  defaultMap() {
    const maps = this.getMapsMenu()
    return maps.length ? this.getMapsMenu()[0].id! : ""
  }

  /**
   * Select a map. Needs to ne a lambda, not a regular function, to avoid binding issues.
   * @param name Map to select.
   */
  selectMap = (name: string) => {
    if (!(name in this.mapDefinitionForMap) || this.currentMapName === name) {
      return
    }
    this.currentMapName = name
    if (this.mapLoaded) {
      this.loadMap()
      if (this.renderer.getZoom() < (this.mapDefinitionForMap[name].minzoom ?? this.zoomLevelMin)) {
        this.renderer.setZoom(this.mapDefinitionForMap[name].minzoom ?? this.zoomLevelMin)
      } else if (this.renderer.getZoom() > (this.mapDefinitionForMap[name].maxzoom ?? this.zoomLevelMax)) {
        this.renderer.setZoom(this.mapDefinitionForMap[name].maxzoom ?? this.zoomLevelMax)
      }
    }
  }

  addMap(mapId: string, mapDefinition: any) {
    // Do not select features from the maps.
    this.skipOverlaysInQueries[`${this.mapNamePrefix}${mapId}`] = true

    // Check map definition. We cannot read the file 'type-safe'.
    if (
      !(
        (!(mapId in this.mapDefinitionForMap) &&
          mapDefinition.name &&
          typeof mapDefinition.name === "string" &&
          mapDefinition.url &&
          typeof mapDefinition.url === "string" &&
          mapDefinition.type &&
          typeof mapDefinition.type === "string") ||
        (["raster", "vector"].includes(mapDefinition.type) &&
          ((mapDefinition.type === "vector" &&
            mapDefinition.style &&
            typeof mapDefinition.style === "string" &&
            !mapDefinition.tileSize) ||
            (mapDefinition.type === "raster" &&
              !mapDefinition.style &&
              mapDefinition.tileSize &&
              typeof mapDefinition.tileSize === "number")) &&
          (!mapDefinition.minzoom || typeof mapDefinition.minzoom !== "number") &&
          (!mapDefinition.maxzoom || typeof mapDefinition.maxzoom !== "number"))
      )
    ) {
      throw new Error(`Incorrect map definition: ${mapId}`)
      return
    }
    const map: (VectorSourceSpecification | RasterSourceSpecification) & {
      name: string
      style: string
      styleLayers: any
    } = mapDefinition

    // Fix fields.
    if (!map.minzoom) {
      map.minzoom = this.zoomLevelMin
    }
    if (!map.maxzoom) {
      map.maxzoom = this.zoomLevelMax
    }
    if (map.url) {
      map.url = this.replacePlaceholders(map.url)
    }
    if (map.style) {
      map.style = this.replacePlaceholders(mapDefinition.style)
      map.styleLayers = undefined // These will be loaded during loadMap.
    } else if (map.type === "raster") {
      map.styleLayers = [
        {
          source: `${this.mapNamePrefix}${mapId}`,
          type: "raster",
          minzoom: this.zoomLevelMin,
          maxzoom: this.zoomLevelMax
        }
      ]
    }

    this.mapDefinitionForMap[mapId] = mapDefinition
    const source: VectorSourceSpecification | RasterSourceSpecification = {
      type: mapDefinition.type,
      tiles: [mapDefinition.url]
    }
    if (mapDefinition.tileSize) {
      ;(source as RasterSourceSpecification).tileSize = mapDefinition.tileSize
    }
    source.maxzoom = mapDefinition.maxzoom
    source.minzoom = mapDefinition.minzoom
    this.safeAddSource(`${this.mapNamePrefix}${mapId}`, source)
  }

  zoomToBounds(bounds: BoundingBox) {
    if (bounds.isEmpty()) {
      return
    }
    // Zoom a little bit out.
    bounds = bounds.extendWithLngLatDelta({lng: 0.1, lat: 0.1})
    this.renderer.fitBounds([
      [bounds.southWest.lng, bounds.southWest.lat],
      [bounds.northEast.lng, bounds.northEast.lat]
    ])
  }

  zoomToLevel(level: number) {
    this.renderer.zoomTo(level)
  }

  zoomIn() {
    this.renderer.zoomIn()
  }

  zoomOut() {
    this.renderer.zoomOut()
  }

  goToTile(z: any, x?: any, y?: any) {
    if (x === undefined || y === undefined) {
      const values = z.split(/\D/)
      z = parseFloat(values[0])
      x = parseFloat(values[1])
      y = parseFloat(values[2])
    }
    const resolution = Math.pow(2, z)
    const tileWidthDegree = 360 / resolution
    const minLng = -180 + x * tileWidthDegree
    const maxLng = minLng + tileWidthDegree
    const minY = (y + 1) / resolution
    const maxY = y / resolution
    const minLat = ((2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * minY)) - Math.PI / 2.0) / Math.PI) * 180
    const maxLat = ((2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * maxY)) - Math.PI / 2.0) / Math.PI) * 180
    const bounds = new BoundingBox({lng: minLng, lat: minLat}, {lng: maxLng, lat: maxLat})
    this.renderer.fitBounds([bounds.southWest.lng, bounds.southWest.lat, bounds.northEast.lng, bounds.northEast.lat])
  }

  createOverlayForTool(toolId: string, layers: LayerWithoutId[]) {
    const mapDataOverlay = this.createMapDataOverlay(toolId)
    layers.forEach((layer) => mapDataOverlay.addLayerWithoutId(layer))
    this.skipOverlaysInQueries[mapDataOverlay.source] = [DataSelectorTool.ID, DistanceCalculatorTool.ID].includes(
      toolId
    )
    return mapDataOverlay
  }

  private setOnClickListener(action: (e: MapMouseEvent) => void) {
    this.renderer.on("click", (e: MapMouseEvent) => action(e))
  }

  private createMapDataOverlay(id: string) {
    this.safeAddSource(id, this.createEmptyGeoJsonSource())
    return new MapDataOverlay(
      id,
      this.referenceLayerTools,
      (layer: any, beforeLayerId: string) => this.safeAddLayer(layer, beforeLayerId),
      (id: string) => this.renderer.removeLayer(id),
      (featureCollection: FeatureCollection) => this.setSourceFeatureCollection(id, featureCollection)
    )
  }

  private setSourceFeatureCollection(overlayId: string, featureCollection: FeatureCollection) {
    this.featureCollectionPerTool[overlayId] = featureCollection
    const source = this.renderer.getSource(overlayId)
    if (!(source && "setData" in source && typeof source.setData === "function")) {
      throw new Error(`Function setData missing for map overlay: ${overlayId}`)
    }
    source.setData(featureCollection)
  }

  private convertStyleLayers(layers: LayerSpecification[], sourceName: string) {
    const result: any[] = []
    for (const layer of layers) {
      const layerWithSource = {...layer, source: sourceName}
      result.push(layerWithSource)
    }
    return result
  }

  private safeAddSource(name: string, content: SourceSpecification) {
    if (this.mapLoaded) {
      this.renderer.addSource(name, content)
    } else {
      const elm: MapSourceElement = {
        name: name,
        content: content
      }
      this.saved.sources.push(elm)
    }
  }

  private safeAddLayer(layer: LayerSpecification, beforeLayerId?: string): string {
    layer = {...layer, id: this.nextLayerId.toString()}
    this.nextLayerId += 1
    if (this.mapLoaded) {
      this.renderer.addLayer(layer, beforeLayerId)
    } else {
      const elm: MapLayerGroupElement = {
        beforeLayerId: beforeLayerId,
        layers: [layer]
      }
      this.saved.layerGroups.push(elm)
    }
    return layer.id
  }

  private createEmptyGeoJsonSource(): GeoJSONSourceSpecification {
    return {
      type: "geojson",
      attribution: "TomTom",
      cluster: false,
      maxzoom: this.zoomLevelMax,
      data: {
        type: "FeatureCollection",
        features: []
      }
    }
  }

  private replacePlaceholders(str: string) {
    return str.replace("{tomtom_key}", this.apiKey).replace("{style_version}", this.styleVersion)
  }

  private useLayersAsMap(name: string | undefined, layers: any) {
    if (!name || name !== this.currentMapName) {
      return
    }
    this.currentLayerIds.forEach((element) => this.renderer.removeLayer(element))
    this.currentLayerIds = layers.map((layer: any) => this.safeAddLayer(layer, this.referenceLayerMap))
  }

  private loadMap() {
    if (!this.currentMapName) {
      return
    }
    const map = this.mapDefinitionForMap[this.currentMapName]
    if (!map.style) {
      this.useLayersAsMap(this.currentMapName, map.styleLayers)
      return
    }
    requestJsonFile(
      map.style,
      (json) => {
        map.styleLayers = this.convertStyleLayers(json.layers, `${this.mapNamePrefix}${this.currentMapName}`)
        this.useLayersAsMap(this.currentMapName, map.styleLayers)
      },
      () => {
        this.logWindow.log(`Authorization problem loading map style file; clearing API keys.`)
        throw new InvalidApiKey()
      },
      () => this.logWindow.log(`Cannot load map style: ${map.style}`)
    )
  }

  private onLoad(mapLibreEvent: MapLibreEvent) {
    this.mapLoaded = true
    this.renderer.getCanvas().style.cursor = "crosshair"

    // Add initial layer.
    this.renderer.addSource("empty", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    })

    // Add saved sources.
    this.saved.sources.forEach((element) => this.renderer.addSource(element.name, element.content))

    // Add reference layers.
    const references = [this.referenceLayerMap, this.referenceLayerLayers, this.referenceLayerTools]
    references.forEach((layer) =>
      this.renderer.addLayer({
        id: layer,
        type: "line",
        source: "empty"
      })
    )

    // Load the map.
    this.loadMap()

    // Add saved layer groups.
    this.saved.layerGroups.forEach((group) =>
      group.layers.map((layer: any) => this.renderer.addLayer(layer, group.beforeLayerId))
    )

    // Clear saved data.
    this.saved.sources = []
    this.saved.layerGroups = []
  }
}

export default MapView
