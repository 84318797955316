/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageCallback, TtpMessageCallbacks} from "./parser/ttpMessageCallbacks"
import {TtpMessageParseException} from "./parser/ttpMessageParseException"
import {TtpMessageParseError} from "./parser/ttpMessageParseError"
import {TtpMessageFooter} from "./parser/ttpMessageFooter"
import {TtpMessageComment} from "./parser/ttpMessageComment"
import TtpMessageBase from "./parser/ttpMessageBase"
import TtpMessageHeader from "./parser/ttpMessageHeader"
import TtpMessageSensorConfiguration from "./parser/ttpMessageSensorConfiguration"
import TtpMessageParameter from "./parser/ttpMessageParameter"
import TtpMessagePositioningXml from "./parser/ttpMessagePositioningXml"
import {TtpMessageSensorFactory} from "./parser/ttpMessageSensorFactory"

/**
 * --------------------------------------------------------------------------------------------
 * Example of use:
 *
 *   const parser = new TtpFileParser()
 *   parser.registerMessageCallback("SensorIncomingLocations", (message: TtpMessageSensorIncomingLocations) =>
 *     console.log(`Incoming locations: ${message.longitude}, ${message.latitude}`)
 *
 *   fs.readFile(filePath, "utf8", (err, data) => {
 *     if (!err) parser.parse(data)
 *   }
 *
 * You can use `parser.parse(data)` for a complete file, or on individual lines, if you're using
 * the parser to parse line by line. The `parse` method can start anywhere in a TTP file. If you
 * use it to parse line by line, you can pass the (optional) line number as the second argument.
 *
 * The parser tries to be a little bit smart about time stamps. TTP message have monotonic time stamps,
 * but are lacking "real time" time stamps, sometimes. In such cases it tries to reconstruct a real
 * time field, so the messages can be filtered by time easily.
 * --------------------------------------------------------------------------------------------
 */

export class TtpParser {
  messageCallbacks: Required<TtpMessageCallbacks>
  lastParsedTime?: Date
  deltaFromMonotonicTimeToTime?: number

  constructor() {
    this.messageCallbacks = {
      Header: [],
      Footer: [],
      Comment: [],
      Parameter: [],
      PositioningXml: [],
      SensorConfiguration: [],
      SensorMapMatcherResult: [],
      SensorLocationPrediction: [],
      SensorLaneLevelPrediction: [],
      SensorLocationEvent: [],
      SensorMapMatcherInputLocations: [],
      SensorIncomingLocations: [],
      SensorUnknown: [],
      ParseError: [TtpParser.defaultParseError]
    }
    this.lastParsedTime = undefined
    this.deltaFromMonotonicTimeToTime = undefined
  }

  // It can be removed by calling clearCallbacks().
  private static readonly defaultParseError: TtpMessageCallback<any> = (parseError: TtpMessageParseError) => {
    const error = `TTP parse error: ${parseError.reason}, message: ${parseError.lineNumber ? parseError.lineNumber + ", " : ""}${parseError.line}`
    console.warn(error)

    // This exception will need to be caught by the caller.
    throw new TtpMessageParseException(error)
  }

  registerMessageCallback<K extends keyof TtpMessageCallbacks>(
    messageType: K,
    callback: TtpMessageCallback<any>
  ): void {
    if (messageType === "ParseError" && this.messageCallbacks[messageType].includes(TtpParser.defaultParseError)) {
      // The MessageParseError callback is a special case that has a default. We need to deregister the default first.
      this.messageCallbacks[messageType] = []
    }

    if (!this.messageCallbacks[messageType].includes(callback)) {
      this.messageCallbacks[messageType].push(callback)
    }
  }

  registerMessageCallbacksForAll(callback: TtpMessageCallback<any>): void {
    for (const key in this.messageCallbacks) {
      this.registerMessageCallback(key as keyof TtpMessageCallbacks, callback)
    }
  }

  clearMessageCallbacks(): void {
    for (const key in this.messageCallbacks) {
      this.messageCallbacks[key as keyof TtpMessageCallbacks] = []
    }
  }

  parse(data: string, startLineNumber: number = 1): void {
    const lines = data.split("\n")
    for (let lineNumber = 0; lineNumber < lines.length; ++lineNumber) {
      const message = this.parseSingleMessage(lineNumber + startLineNumber, lines[lineNumber])

      if (message.time) {
        // If there's a time, keep it.
        this.lastParsedTime = message.time

        if (message.monotonicTime) {
          // Recalculate delta if we can.
          this.deltaFromMonotonicTimeToTime = message.time.getTime() / 1000 - message.monotonicTime
        }
      } else {
        if (message.monotonicTime && this.deltaFromMonotonicTimeToTime) {
          // If there's only a monotonic time, calculate the real time.
          message.time = new Date((message.monotonicTime + this.deltaFromMonotonicTimeToTime) * 1000)
        } else if (this.lastParsedTime) {
          // If there's no monotonic time, or no delta, use the last time.
          message.time = this.lastParsedTime
        }
      }

      // Call callbacks for the message.
      const messagePrefix = "TtpMessage"
      const messageType = message.constructor.name
      if (!messageType.startsWith(messagePrefix)) {
        throw new Error(`Invalid message type: ${messageType}`)
      }
      const key = messageType.substring(messagePrefix.length) as keyof TtpMessageCallbacks
      const callbacks = this.messageCallbacks[key] as TtpMessageCallback<typeof message>[]

      // Throw parse error if no callbacks registered for it.
      if (message instanceof TtpMessageParseError && callbacks.length === 0) {
        throw message
      }

      // Otherwise, execute callbacks, if there are any.
      callbacks.forEach((callback) => callback(message))

      // If this was the END message, stop parsing.
      if (message instanceof TtpMessageFooter) {
        break
      }
    }
  }

  private parseSingleMessage(lineNumber: number, line: string): TtpMessageBase {
    try {
      const parsers = [
        // Note: the order of parser is important as some of the regex's overlap.
        TtpMessageComment.parse,
        TtpMessageHeader.parse,
        TtpMessageFooter.parse,
        TtpMessageSensorConfiguration.parse,
        TtpMessageParameter.parse,
        TtpMessagePositioningXml.parse,
        TtpMessageSensorFactory.parse
      ]
      line = line.trim()
      for (const parse of parsers) {
        const message = parse(lineNumber, line)
        if (message) {
          return message
        }
      }
      return new TtpMessageParseError(lineNumber, "Syntax error", line)
    } catch (e: any) {
      if (e instanceof TtpMessageParseException) {
        return new TtpMessageParseError(lineNumber, e.reason, line)
      }
      throw e
    }
  }
}

export default TtpParser
