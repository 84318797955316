/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {APPLICATION_VERSION} from "../common/version"
import {marked} from "marked"
import HtmlWindow from "./htmlWindow"

export class HelpWindow extends HtmlWindow {
  private readonly MARKDOWN_HELP_CONTENT = `# Map Data Visualizer ${APPLICATION_VERSION}

## The user interface

If you're not familiar at all with the Map Data Visualizer, scroll down for general information. Here, we will
start with explaining the user interface.

![Data selector](tool-data-selector.png)
### Tool: Select Data On Map

The \`Select data on map\` tool allows you to click on the map and a yellow info box will appear with information about
the selected map feature. Note that clicking on the map and selecting features may also be supported in some other
tools (as a default action).

![Distance calculator](tool-distance-calculator.png)
### Tool: Measure distances 

The \`Measure distances\` tool allows you to draw a line on the map and measure its length. The length is displayed in
meters and kilometers.

![Export route as JSON](tool-export-json.png) ![Export route as GPX](tool-export-gpx.png) 
### Tool: Create/Export Route As JSON/GPX

The \`Create/export route as JSON\` and \`Export route as GPX\` tools allows you to draw a route on the map and then
export it as a JSON or GPX file. The file will contain the route coordinates and other information.

![Map tile inspector](tool-tile-editor.png)
 ## Tool: Map tile inspector

The \`Map tile inspector\` allows you to click on a map tile and opens up a window with information about that tile. 
Disclaimer: This functionality is only useful for map tile experts. 

### Keyboard shortcuts

* Keys **0-9**: Filter data by **tile levels**. For example, press **2** to show tile levels 12 and higher. 
Press keys 0-7 for levels 10-17 (and 8, 9 for levels 8, 9). If you press a key multiple times 
you will see that the filter flip between \`>=\`, \`=\`, \`<=\` and \`ALL\`. The \`ALL\` option can **only** be selected
by pressing a digit multiple times. You cannot select it by clicking on the menu option itself.   
* Key **Z**: Toggle the **auto-zoom** feature. When enabled, the map will automatically zoom to the extent of the data. Tip: Switch 
this off if you are interested in a selected area and wish to change the tile level filter. 
* Keys **B**, **I**, **N**, ...: Show or hide **basic map** tiles, **incidents** tiles, **NDS.Live** tiles, etc. You can find 
the keyboard shortcuts in the \`Layers\` menu. 
* Key **C**: Clear the entire map and remove data. 
* **Escape**: Reset the timeline range, but keep the data. 

### Analyzing files

You can drop TTP files, \`mitm-proxy\` logs, \`http\` logs any other text files on the map. The tool will try to 
visualize them.
 
By default, or if the \`Select data on map\` tool is selected, you can click on any visualized data on the map to get
information about that data. If there were duplicates of the same data, the data will have a red border around it, and
the additional info will state how many duplicates were found. Tiles is volatile layers (like traffic incidents and flow) 
will only have a red border if they were re-retrieved within the last 90 seconds. The amount of data in duplicate tiles
is also shown in the timeline window.

Check out the toolbar with the tool icons for tools like **distance measurement**, **export GPX route**, and more.

The **timeline** window at the bottom of the screen shows the time range of the data. This only works for data that has
some form of time stamp attached to it, of course. Selecting a time range will filter the data to only show data within
that range. It will also show the **total number of bytes** that were reported for that data. If size information is missing
for some events, where it was expected, the reported number of bytes will be marked "**(N items incomplete)**", so you know the
value may not be accurate. The timeline also shows how much data was involved in duplicate tile requests.

**Important:** Log files do not always report the correct number of bytes, or none at all. Also, any TCP or HTTP
overhead is usually not taken into account, so this number may not be 100% accurate!

### The main map canvas

The map canvas is always shown and you can drag and drop files on top of it, or select files using the \`Select files\`
buttons. The map canvas will will try to visualize text files with the following types of data:

* GPX files
* GeoJSON files
* JSON files
* TTP files.
* or other, less structured text files, which contains URLs with map tile coordinates, for example.

The tool will try to analyze the file and visualize its contents. Data that will be recognized from text files is:

* Map visualization tiles from URLs: If a URL contains something like \`/map-display/tile/{z}/{x}/{y}.pbf\`, the tool
  will try to visualize the tiles on the map.
* NDS.Live tiles in URLs: If a URL contains something like \`nds-{...}-tiles/{n}/tiles\`, the tool will try to visualize
  the NDS.Live tiles on the map.

It cannot read binary files, however, like replay files from \`mitm\`. You may get unexpected results if you try those.

The map canvas is interactive and you can zoom in and out, pan the map, and click on features to get more information.
Use the tools from the toolbar to select a tool to use on the map. 

## General information

The \`map data visualizer\` is an application for geographical visualizing data in text or log files. This includes
visualizing map tile URLs, routes and coordinates. To run the application you will need a TomTom API key that has access 
to the TomTom Maps SDK for Web and the Online Routing API.

Drop a text file on the map to visualize its content. You can drop GPX, JSON, GeoJSON, CSV files or other formats.
The following data is recognized and parsed:
**GPX**, 
**GeoJSON**, MapVis
**basic map tiles**,
**traffic incidents tiles**,
**traffic flow tiles**,
**hillshade tiles**,
**NDS.Live tiles**,
**NavTiles** (NK2),
**Routing API calls**,
**TTP** files, and
**coordinates pairs** (prefixed or using separators).

You can apply filters to show/hide data from specific files, or different types of data, or show only specific tile
levels. You can also select the "select data on map data" tool to click on something on the map (like a tile) and get
meta data for that element (like the original URL it came from).

### TomTom API key

You can obtain an API key on \`developer.tomtom.com\` if needed. The API key is stored
in the local storage of your browser after you entered it. The API key is sometimes sent as part of the URL, so be
careful when sharing logs with URLs with others. On a public machine, use "incognito" mode to not persist data in local
storage.

## Development information

For development information, consult the \`README.md\` file in the root of the project.

----

**For more info contact:**

**Rijn Buve** *(with special credits to Sebastian Bukowiecki for the initial idea)*

Summer 2024`

  constructor(htmlElementId: string) {
    super(htmlElementId)
    const content = document.getElementById(`${htmlElementId}-content`)
    if (!content) {
      throw new Error(`HelpWindow: could not find element with id '${htmlElementId}-content'`)
    }
    content.innerHTML = marked(this.MARKDOWN_HELP_CONTENT) as string
  }
}

export default HelpWindow
